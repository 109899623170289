import React from 'react';
import PropTypes from 'prop-types';
import styles from './Item.module.scss';
import { useClassName } from './useClassName';

const Item = ({
  title,
  subTitle,
  containerType,
}) => {
  const [containerClassName] = useClassName(true, false);

  return (
    <article className={containerClassName}>
      <div className={styles.bannerContainer}>
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>
          <span>{title?.trim()}</span>
        </h3>
        <p className={styles.subTitle}>{subTitle}</p>
      </div>
    </article>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  containerType: PropTypes.oneOf(['swimlane', 'grid']),
};

export default Item;
