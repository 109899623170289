import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import Href from '../Href';
import VideoPlayBtn from '../VideoPlayBtn';
import { getInnerUrl } from '../../utils/urls';
import { safelyGetInnerHTML } from '../../utils/html';

import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { Button } from 'cet-components-lib/dist/UI/Button';

import styles from './TextImageBanner.module.scss';

const TextImageBanner = ({
  title = "", text = "", id = "",
  image = "", image_mobile = "", image_side = "start",
  animate_json = "", animate_json_mobile = "",
  video = "", video_poster = "", video_poster_mobile = "",
  has_button = false, button_text = "", button_link = "", button_link_target = '_blank',
  skinType = "default",
  type, onClick,
  className = '',
  children }) => {
  const [relativeLink, setRelativeLink] = useState('');
  const { isMobileView } = useDeviceData();

  useEffect(() => {
    setRelativeLink(getInnerUrl(button_link));
  }, [button_link]);

  const getImage = () => {
    let imageComp = <></>;
    if (type) {
      if (type === 'image') {
        const imageUrl = isMobileView ? (image_mobile || image) : image;
        imageComp = <>{imageUrl && <div className={`${styles['textImageBanner-image']} ${styles['textImageBanner-image--' + (isMobileView ? 'mobile' : 'pc')]}`} style={{ backgroundImage: `url('${imageUrl}')` }}></div>}</>;
      }
      else if (type === 'animate') {
        const animateUrl = isMobileView ? (animate_json_mobile || animate_json) : animate_json;
        imageComp = <>{animateUrl && <div className={`${styles['textImageBanner-animate']} ${styles['textImageBanner-animate--' + (isMobileView ? 'mobile' : 'pc')]}`}><Player autoplay={true} loop={true} controls={false} src={animateUrl} ></Player></div>}</>;
      }
      else if (type === 'video') {
        const videoPosterUrl = isMobileView ? (video_poster_mobile || video_poster) : video_poster;
        imageComp = <>
          <div className={`${styles['textImageBanner-image']} ${styles['textImageBanner-image--' + (isMobileView ? 'mobile' : 'pc')]}`} style={{ backgroundImage: `url('${videoPosterUrl}')` }}>
            <VideoPlayBtn videoUrl={video} />
          </div>
        </>;
      }
    }
    else {
      const imageUrl = isMobileView ? (image_mobile || image) : image;
      const animateUrl = isMobileView ? (animate_json_mobile || animate_json) : animate_json;
      imageComp = <>
        {animateUrl && <div className={`${styles['textImageBanner-animate']} ${styles['textImageBanner-animate--' + (isMobileView ? 'mobile' : 'pc')]}`}><Player autoplay={true} loop={true} controls={false} src={animateUrl} ></Player></div>}
        {!animateUrl && imageUrl && <div className={`${styles['textImageBanner-image']} ${styles['textImageBanner-image--' + (isMobileView ? 'mobile' : 'pc')]}`} style={{ backgroundImage: `url('${imageUrl}')` }}></div>}
      </>;
    }

    return imageComp;
  }

  return (text && <div className={`${styles.textImageBanner} ${className} ${styles['textImageBanner_' + skinType]}`}>
    <div className={styles['textImageBanner-content']} image_side={image_side}>
      <div className={styles['textImageBanner-title']}>{safelyGetInnerHTML(title)}</div>
      {getImage()}
      <div className={styles['textImageBanner-text']}>
        <span>{safelyGetInnerHTML(text)}</span>
        {children}
      </div>
      {has_button && onClick && <div
        className={styles.buttons}>
        <Button
          id="nwaTextImageBannerButton"
          onClick={onClick}
          type="primary"
        >
          <span className={styles['textImageBanner-btn-text']}>
            {button_text}
          </span>
        </Button>
      </div>}
      {has_button && !onClick && <div className={styles.buttons}>
        <Href
          className={styles['btn-blue']}
          id={`button-${button_text.replace(/ /g, '_')}`}
          category={['OpenStudio', 'BuyStudio'].includes(id) ? 'Tools' : null}
          label={['OpenStudio', 'BuyStudio'].includes(id) ? id : null}
          href={relativeLink}
          target={button_link_target}
          type="primary"
        >
          <span className={styles['textImageBanner-btn-text']}>
            {button_text}
          </span>
        </Href>
      </div>}
    </div>
  </div>);
};

TextImageBanner.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  image_mobile: PropTypes.string,
  image_side: PropTypes.oneOf(['start', 'end']),
  animate_json: PropTypes.string,
  animate_json_mobile: PropTypes.string,
  has_button: PropTypes.bool,
  button_text: PropTypes.string,
  button_link: PropTypes.string,
  button_link_target: PropTypes.oneOf(['_top', '_blank'])
};

export default TextImageBanner;