import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Item.module.scss';

const YearCycles = ({ yearCycles }) => {
    return yearCycles && yearCycles.length > 0 ? <div className={styles.yearCycles}>{yearCycles.map(yearCycle => yearCycle.name).join(', ')}</div> : <></>;
};

YearCycles.propTypes = {
    yearCycles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        orderBy: PropTypes.number,
        parentId: PropTypes.string,
      })
    ),
};

export default YearCycles;
