import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { useSelector } from 'react-redux';
import { useGenderFormatMessage } from "./useGenderFormatMessage";
import { UiContext } from "../contexts";
import { myStudioBasicProductID, myStudioExpandedProductID } from '../utils/consts';

export const useSideBar = () => {
  const { isMobileView } = useDeviceData();
  const intl = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const [{ }, updateUiData] = useContext(UiContext);
  const { isLoggedIn, productSubscriptions } = useSelector(({ user }) => ({
    isLoggedIn: Boolean(user?.info?.userId),
    productSubscriptions: user.productSubscriptions,
  }));

  const hasStudioSubscription = productSubscriptions?.filter(subscription => (subscription.source === 'generalProducts' && (subscription.productID === myStudioBasicProductID || subscription.productID === myStudioExpandedProductID))).length > 0;

  const handleClick = (e, url) => {
    e.preventDefault();
    if (url !== '') {
      history.push(`/${intl.locale}/${url}`);
    } else {
      history.push(`/${intl.locale}`);
    }
  }

  const handleMyContent = (e, url) => {
    e.preventDefault();
    updateUiData({ oldSiteUrl: getGenderFormatMessage('mybag_items_url') });
  }

  const handleNewContent = (e, url) => {
    if (hasStudioSubscription) {
      const myStudioLink = getGenderFormatMessage('myStudio_link');
      window.open(myStudioLink, '_blank');
    } else {
      e.preventDefault();
      history.push(`/${intl.locale}/tools/studio`);
    }
  }

  return useMemo(() => {
    const toReturn = [];
    const catalog = { id: 'sideBar_catalog', onClick: (e) => handleClick(e, 'catalog'), label: getGenderFormatMessage('catalog'), isSelected: pathname === `/${intl.locale}/catalog` }
    const books = { id: 'sideBar_books', onClick: (e) => handleClick(e, 'books'), label: getGenderFormatMessage('sidebar_books'), isSelected: pathname === `/${intl.locale}/books` }
    const courses = { id: 'sideBar_courses', onClick: (e) => handleClick(e, 'courses'), label: getGenderFormatMessage('sidebar_courses'), isSelected: pathname === `/${intl.locale}/courses` }
    const tools = { id: 'sideBar_creation_tools_menu', onClick: (e) => handleClick(e, 'tools'), label: getGenderFormatMessage('sidebar_tools'), isSelected: pathname === `/${intl.locale}/tools` }
    const aiMain = { id: 'sideBar_aiMain_menu', onClick: (e) => handleClick(e, 'ai-main'), label: getGenderFormatMessage('sidebar_ai_main'), isSelected: pathname === `/${intl.locale}/ai-main`, icon: "Stars", isNewItem: true }
    const teachersLearn = { id: 'learn_for_teachers_pageTitle', onClick: (e) => handleClick(e, 'teachers-learn'), label: getGenderFormatMessage('learn_for_teachers_pageTitle'), isSelected: pathname === `/${intl.locale}/teachers-learn`, icon: "PedagogicalHelp" }
    const myContent = { id: 'sideBar_myContent', onClick: (e) => handleMyContent(e, 'my-content'), label: getGenderFormatMessage('my_content'), isSelected: pathname === `/${intl.locale}/my-content` }
    // const recommendations = { id: 'sideBar_recommendations_mobile', onClick:  (e) => handleClick(e,'recommendations'), label: getGenderFormatMessage('content_recommendations'), isSelected: pathname === `/${intl.locale}/recommendations` }
    const favorites = { id: 'sideBar_favorites_mobile', onClick: (e) => handleClick(e, 'my-favorites'), label: getGenderFormatMessage('favorites'), isSelected: pathname === `/${intl.locale}/my-favorites`, icon: "Favorites" }
    const tasks = { id: 'sideBar_tasks_menu_mobile', onClick: (e) => handleClick(e, 'tasks'), label: getGenderFormatMessage('tasks_management'), isSelected: pathname === `/${intl.locale}/tasks`, icon: "Tasks" }
    const home = { id: 'sideBar_homePage_mobile', onClick: (e) => handleClick(e, ''), label: getGenderFormatMessage('home_page'), isSelected: pathname === `/${intl.locale}`, icon: "Home" }
    const content = { id: 'sideBar_content_menu', label: getGenderFormatMessage('learning_subjects'), icon: 'Flower', items: [books, courses]}

    const newContent = { id: 'sideBar_newContent', onClick: (e) => handleNewContent(e, 'new-content'), label: getGenderFormatMessage('sidebar_new_content'), isSelected: pathname === `/${intl.locale}/tools/studio` }
    const myStudio = { id: 'sideBar_myStudio_menu', label: getGenderFormatMessage('my_studio'), icon: 'Studio', items: [newContent, myContent], isMoreMenuItemOpen: false }
    const selTime = { id: 'sideBar_selTime', onClick: (e) => handleClick(e, 'sel-time'), label: getGenderFormatMessage('sel_time'), isSelected: pathname === `/${intl.locale}/sel-time` }
    const teachingSkills = { id: 'sideBar_teachingSkills', onClick: (e) => handleClick(e, 'teaching-skills'), label: getGenderFormatMessage('teaching_skills'), isSelected: pathname === `/${intl.locale}/teaching-skills` }
    const teachingPractices = { id: 'sideBar_teachingPractices', onClick: (e) => handleClick(e, 'teaching-practices'), label: getGenderFormatMessage('teaching_practices'), isSelected: pathname === `/${intl.locale}/teaching-practices` }
    const teachersRoom = { id: 'sideBar_teachersRoom_menu', label: getGenderFormatMessage('sidebar_teachers_room'), icon: 'TeachersRoom', withTopSeparator: true, items: [selTime, teachingSkills, teachingPractices], isMoreMenuItemOpen: false }

    if (isMobileView) {
      catalog.icon = 'Content';
      const orderedContentItems = [{ ...catalog, icon: null }, books, courses, tools];
      content.items = orderedContentItems;
      toReturn.push(home, catalog, tasks, favorites, aiMain, content, teachersRoom);
    }
    else {
      content.items.unshift(catalog);      
      toReturn.push(content, { ...tools, icon: 'Studio' }, teachersLearn);
      
      isLoggedIn &&
        content.items.push(tools) &&
        toReturn.splice(1, 2, favorites, tasks, myStudio, teachersRoom);
      
      isLoggedIn && intl.locale === 'he' ? toReturn.unshift(home, aiMain) : toReturn.unshift(home);
    }

    return toReturn;
  }, [intl, isMobileView, pathname, isLoggedIn, handleNewContent]);

};
