import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import EliteSystemMessage from '../../components/EliteSystemMessage';
import DominateSystemMessage from '../../components/DominateSystemMessage';
import { useSystemMessages } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { CacheProvider } from '../../utils/cacheProvider';

export default function SystemMessagesContainer() {
  const { elite, dominate } = useSystemMessages();
  const [showElite, setShowElite] = useState(false);
  const [showElitePopup, setShowElitePopup] = useState(false);
  const [showDominate, setShowDominate] = useState(false);
  const { userInfo } = useSelector(({ user }) => ({
    userInfo: user.info
  }));
  const { isMobileView } = useDeviceData();

  const showMessage = useCallback(
    message => {
      let showMessage =
        message &&
        (!message.userType || message.userType === 'everyone' || (message.userType === 'teacher' && userInfo.userId) || (message.userType === 'guest' && !userInfo.userId)) &&
        (!message.targetDevice ||
          message.targetDevice === 'all' ||
          (message.targetDevice === 'desktop' && !isMobileView) ||
          (message.targetDevice === 'mobile' && isMobileView));

      if (showMessage) {
        const type = message.type;

        let user = 'all';
        if (message.userType === 'teacher' && userInfo.userId) {
          user = userInfo.userId;
        } else if (message.userType === 'guest' && !userInfo.userId) {
          user = 'guest';
        }

        let device = 'all';
        if (message.targetDevice === 'desktop' && !isMobileView) {
          device = 'desktop';
        } else if (message.targetDevice === 'mobile' && isMobileView) {
          device = 'mobile';
        }

        const sessionKey = `SystemMessageClosed_${type}_${user}_${device}`;
        if (CacheProvider.get(sessionKey) === 'true') {
          return false;
        }
        CacheProvider.set(sessionKey, 'true');
      }

      return showMessage;
    },
    [userInfo, isMobileView]
  );

  useEffect(() => {
    const show = showMessage(elite);
    if (show) {
      setShowElite(true);
    }
    setShowElitePopup(false);
  }, [elite, userInfo, isMobileView, showMessage]);

  useEffect(() => {
    const show = showMessage(dominate);
    if (show) {
      setShowDominate(true);
    }
  }, [dominate, userInfo, isMobileView, showMessage]);

  const handleEliteClose = () => {
    setShowElite(false);
  };

  const handleElitePopupOpen = () => {
    setShowElitePopup(true);
  };

  const handleElitePopupClose = () => {
    setShowElitePopup(false);
  };

  const handleDominateClose = () => {
    setShowDominate(false);
  };

  return (
    <>
      {userInfo && (
        <>
          {showElite && (
            <EliteSystemMessage
              {...elite}
              show={showElite}
              showPopup={showElitePopup}
              handleClose={handleEliteClose}
              handlePopupOpen={handleElitePopupOpen}
              handlePopupClose={handleElitePopupClose}
            />
          )}
          {showDominate && <DominateSystemMessage {...dominate} show={showDominate} handleClose={handleDominateClose} />}
        </>
      )}
    </>
  );
}
