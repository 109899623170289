import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useGenderFormatMessage } from '../../hooks';
import styles from './Preview.module.scss';
import { setPathnameParam } from '../../utils/urls';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter } from '../../utils/urls';

import PreviewContent from './PreviewContent';
import { NanoCourseRecommendationsPopup } from './Partials/NanoCourseRecommendations';

import Icon from '../Icon';
import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';
import Modal from '../Modal';
import HorizontalSwipeLayout from '../HorizontalSwipeLayout';
import { CacheProvider } from '../../utils/cacheProvider';

const emptyObj = {};

const Preview = ({ itemId, containerItems, onClose, itemExternalId, itemUrl, itemType, hideButtons }) => {
  const intl = useIntl();

  const { getGenderFormatMessage } = useGenderFormatMessage();
  const history = useHistory();
  const { isMobileView } = useDeviceData();
  const { sendUiUsageEvent } = useCetEvents();
  const [nextItemId, setNextItemId] = useState(null);
  const [prevItemId, setPrevItemId] = useState(null);
  const [currentItemIndex, setCurrentIndex] = useState(-1);
  const [popupClass, setPopupClass] = useState(false);

  const [containerItemsData, setContainerItemsData] = useState({});
  const [containerItemsList, setContainerItemsList] = useState([]);
  const [containerItemsTemp, setContainerItemsTemp] = useState(null);
  const [showSwipeAnimate, setShowSwipeAnimate] = useState(false);

  const [showNanoCourseRecommendationsAnimation, setShowNanoCourseRecommendationsAnimation] = useState(false);
  const [nanoCourseRecommendationsItems, setNanoCourseRecommendationsItems] = useState(null);
  const [showNanoCourseRecommendationsItems, setShowNanoCourseRecommendationsItems] = useState(false);

  const [innerPopup, setInnerPopup] = useState();

  const previewContainerItems = useRef();

  useEffect(() => {
    setNanoCourseRecommendationsItems(null);
    setShowNanoCourseRecommendationsItems(false);
  }, [itemId]);

  useEffect(() => {
    previewContainerItems.current = containerItemsList;
  }, [containerItemsList]);

  useEffect(() => {
    let nextItemId = null,
      prevItemId = null;

    if (containerItemsTemp !== containerItems) {
      setContainerItemsTemp(containerItems);
      previewContainerItems.current = containerItems ?? [];

      if (previewContainerItems.current.indexOf(itemId) < 0) {
        previewContainerItems.current = [itemId];
      }

      setContainerItemsList(previewContainerItems.current);
      setContainerItemsData(emptyObj);
    } else if (!containerItems) {
      previewContainerItems.current = [itemId];
      setContainerItemsList(previewContainerItems.current);
      setContainerItemsData(emptyObj);
    }

    if (itemId && previewContainerItems.current && previewContainerItems.current.length && previewContainerItems.current.length > 1) {
      const itemIndex = previewContainerItems.current.indexOf(itemId);
      if (itemIndex >= 0) {
        nextItemId = itemIndex < previewContainerItems.current.length - 1 ? previewContainerItems.current[itemIndex + 1] : null;
        prevItemId = itemIndex > 0 ? previewContainerItems.current[itemIndex - 1] : null;
      }
    }

    setNextItemId(nextItemId);
    setPrevItemId(prevItemId);
  }, [itemId, containerItems, containerItemsTemp]);

  useEffect(() => {
    if (!containerItemsData[itemId]) {
      let newContainerItemsData = { ...containerItemsData };
      newContainerItemsData[itemId] = {};
      setContainerItemsData(newContainerItemsData);
    }
  }, [containerItemsData, itemId]);

  useEffect(() => {
    if (itemId && containerItemsList && containerItemsList.length > 0) {
      const itemIndex = containerItemsList.indexOf(itemId);
      setCurrentIndex(itemIndex);
    }
  }, [itemId, containerItemsList]);

  const isWordpressType = itemType === 'Lo' && /^[0-9]+$/.test(itemExternalId);
  let wordpressType = '';
  if (isWordpressType) {
    const url = new URL(itemUrl);
    wordpressType = capitalizeFirstLetter(url.hostname.replace('www.', '').split('.')[0]);
  }

  let optionalData = {
    interactionType: isWordpressType ? wordpressType : itemType
  };

  let key = itemType === 'Book' || itemType === 'Course' || itemType === 'Nano' || isWordpressType ? 'contentGroupingId' : 'loId';
  optionalData[key] = itemExternalId;

  const handleClose = useCallback(() => {
    if (showNanoCourseRecommendationsItems) {
      onClose();
    } else if (nanoCourseRecommendationsItems && nanoCourseRecommendationsItems.items && nanoCourseRecommendationsItems.items.length > 0) {
      setShowNanoCourseRecommendationsItems(true);
    } else {
      onClose();
    }
  }, [nanoCourseRecommendationsItems, onClose, showNanoCourseRecommendationsItems]);

  const handleNextBtnClick = () => {
    setNewItem(nextItemId);
    const categoryName = 'tileLevel3';
    const labelName = 'swimLane_next';
    sendUiUsageEvent({ category: categoryName, label: labelName, optionalData: optionalData });
  };

  const handlePrevBtnClick = () => {
    setNewItem(prevItemId);
    const categoryName = 'tileLevel3';
    const labelName = 'swimLane_previous';
    sendUiUsageEvent({ category: categoryName, label: labelName, optionalData: optionalData });
  };

  const setNewItem = useCallback(id => {
    let historyState = setPathnameParam('item', id);
    history.push(`${historyState.pathname}${historyState.search}`, historyState);
  }, [history]);

  const handleInnerPopupChange = useCallback((id, innerPopup) => {
    setInnerPopup(innerPopup);
    setPopupClass(false);
  }, []);


  const handleItemReady = useCallback(id => {
    if (!containerItemsData) {
      let newContainerItemsData = { ...containerItemsData };
      if (!newContainerItemsData[id]) {
        newContainerItemsData[id] = {};
      }
      newContainerItemsData[id].isReady = true;
      setContainerItemsData(newContainerItemsData);
    }
  }, [containerItemsData]);


  useEffect(() => {
    let isActive = true;
    setShowSwipeAnimate(false);

    if (
      isMobileView &&
      !innerPopup &&
      itemId &&
      containerItemsData &&
      containerItemsData[itemId] &&
      containerItemsData[itemId].isReady &&
      !CacheProvider.get('PreviewSwipeAnimateDone')
    ) {
      if (nextItemId || prevItemId) {
        setShowSwipeAnimate(true);
        CacheProvider.set('PreviewSwipeAnimateDone', '1');

        setTimeout(() => {
          if (isActive) {
            setShowSwipeAnimate(false);
            setShowNanoCourseRecommendationsAnimation(true);
          }
        }, 5000);
      }
    } else if (
      ((CacheProvider.get('PreviewSwipeAnimateDone') && isMobileView) || !isMobileView) &&
      !innerPopup &&
      itemId &&
      containerItemsData &&
      containerItemsData[itemId] &&
      containerItemsData[itemId].isReady
    ) {
      setShowNanoCourseRecommendationsAnimation(true);
    }

    return () => {
      isActive = false;
    };
  }, [isMobileView, containerItemsData, itemId, nextItemId, prevItemId, innerPopup]);

  useEffect(() => {
    setNewItem(itemId)
  }, [itemId, setNewItem]);

  return (
    <Modal
      isActive={true}
      onClose={handleClose}
      withCloseButton={!isMobileView || !innerPopup || showNanoCourseRecommendationsItems}
      enableCloseFromOutside={innerPopup ? false : true}
      closeButtonLabel={getGenderFormatMessage('close')}
      skin={isMobileView ? 'mobileFullScreen' : 'default'}
      className={`${styles.previewModal} ${popupClass || ''}`}
    >
      {showNanoCourseRecommendationsItems && (
        <NanoCourseRecommendationsPopup
          isMobile={isMobileView}
          title={getGenderFormatMessage('preview_nanoCourseRecommendations_title')}
          items={nanoCourseRecommendationsItems && nanoCourseRecommendationsItems.items}
          thumbnailId={nanoCourseRecommendationsItems && nanoCourseRecommendationsItems.thumbnailId}
          onClose={handleClose}
        />
      )}
      {!showNanoCourseRecommendationsItems && (
        <>
          {nextItemId && !innerPopup && (
            <div className={`${styles.buttonContainer} ${styles.next}`}>
              <button
                aria-label={getGenderFormatMessage('next')}
                title={getGenderFormatMessage('next')}
                onClick={handleNextBtnClick}
                className={`${styles.button} ${styles.next}`}
              >
                <Icon icon="arrow" width={25} height={25} strokeWidth={1} />
              </button>
            </div>
          )}
          {prevItemId && !innerPopup && (
            <div className={`${styles.buttonContainer} ${styles.previous}`}>
              <button
                aria-label={getGenderFormatMessage('previous')}
                title={getGenderFormatMessage('previous')}
                onClick={handlePrevBtnClick}
                className={`${styles.button} ${styles.previous}`}
              >
                <Icon icon="arrow" width={25} height={25} strokeWidth={1} />
              </button>
            </div>
          )}
          {containerItemsList && containerItemsList.length > 1 ? (
            <>
              <HorizontalSwipeLayout
                className={`${styles.horizontalSwipeLayout}`}
                slideClassName={styles.slide}
                index={currentItemIndex}
                onSwipeRight={!innerPopup && isMobileView ? (intl.locale !== 'en' ? nextItemId && handleNextBtnClick : prevItemId && handlePrevBtnClick) : null}
                onSwipeLeft={!innerPopup && isMobileView ? (intl.locale !== 'en' ? prevItemId && handlePrevBtnClick : nextItemId && handleNextBtnClick) : null}
                slides={containerItemsList.map((id, i) => {
                  return containerItemsData[id] ? (
                    <PreviewContent
                      key={`PreviewContent--${i}`}
                      setNanoCourseRecommendationsItems={setNanoCourseRecommendationsItems}
                      canShowNanoCourseRecommendationsAnimation={showNanoCourseRecommendationsAnimation}
                      itemId={id}
                      onClose={onClose}
                      onInnerPopupChange={handleInnerPopupChange}
                      onItemReady={handleItemReady}
                      setPopupClass={setPopupClass}
                      hideButtons={hideButtons}
                    />
                  ) : (
                    <div key={`PreviewContent--${i}`}></div>
                  );
                })}
              />
              <div
                className={`${styles.swipeAnimate} ${showSwipeAnimate ? styles.swipeAnimate_show : ''}`}
                onClick={() => {
                  setShowSwipeAnimate(false);
                  setShowNanoCourseRecommendationsAnimation(true);
                }}
              >
                <div className={styles.swipeAnimate_container}>
                  <div className={styles.swipeAnimate_text}>{getGenderFormatMessage('swipe_animate')}</div>
                  <div className={styles.swipeAnimate_icon}>
                    <Icon icon="swipe_to_right" width={'4.56rem'} height={'4.5rem'} strokeWidth={0} />
                  </div>
                </div>
              </div>
            </>
          ) : containerItemsList && containerItemsList.length === 1 ? (
            <PreviewContent
              setNanoCourseRecommendationsItems={setNanoCourseRecommendationsItems}
              canShowNanoCourseRecommendationsAnimation={true}
              itemId={containerItemsList[0]}
              onClose={handleClose}
              onInnerPopupChange={handleInnerPopupChange}
              onItemReady={handleItemReady}
              setPopupClass={setPopupClass}
              hideButtons={hideButtons}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </Modal>
  );
};

Preview.propTypes = {
  itemId: PropTypes.string,
  containerItems: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  itemExternalId: PropTypes.string,
  itemUrl: PropTypes.string,
  itemType: PropTypes.string
};

export default Preview;
