import React from 'react';
import PropTypes from 'prop-types';
import { getQueryStringParams, setUrlParams } from '../../utils/urls';
import { useGenderFormatMessage } from "../../hooks";

import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { Title } from 'cet-components-lib/dist/UI/Title';
import { SwimLane } from 'cet-components-lib/dist/Layout/SwimLane';

import Item from '../../components/Item';
import RandomTextImageBanner from '../../components/RandomTextImageBanner';
import Href from "../Href";

import styles from './SwimlaneList.module.scss';

const SwimlaneList = React.memo(
  ({
    swimlanes,
    maxNumberOfItems = 10,
    displayTotalCount = true,
    titleClassName = '',
    viewKey = 'itemTypes',
    viewValue = '',
    className = null,
    hasRandomBanner = false,
    pageKey,
    randomBannerPosition = 4,
    hasDescription = false,
    isAiPage = false
  }) => {
    const { isMobileView, isMobile } = useDeviceData();
    const queryParams = { ...getQueryStringParams() };
    const { getGenderFormatMessage } = useGenderFormatMessage();

    const createSwimlane = (swimlane, index) => {
      queryParams[viewKey] = viewValue ? viewValue : swimlane ? swimlane[viewKey] : '';
      const { search: queryString } = setUrlParams(queryParams);
      const allItemsHomepageHref = pageKey === 'home' && viewValue?.startsWith('my') ? `/${viewValue.slice(2).toLowerCase()}/?view=${viewValue}&backToHomepage=true` : '';
      const allItemsHref = swimlane?.allItemsHref || window.location.pathname + (allItemsHomepageHref || queryString);
      const swimLaneItemsIds = swimlane?.data?.length > 0 ? swimlane.data.map(item => item.id) : [];

      const items = swimlane?.data.map((item, index) => {
        return <div className={styles.item} key={index}>
          <Item
            data={item}
            index={index + 1}
            containerItemsIds={swimLaneItemsIds.length === 0 ? [item.id] : swimLaneItemsIds}
            parentTitle={swimlane.title || swimlane.text}
            containerType="swimlane"
          />
        </div>
      });

      return (
        <React.Fragment key={index}>
          {swimlane?.data?.length > 0 && (
            <div key={index} className={`${styles.swimlane} ${className || ''}`}>
              {swimlane.title && (
                <div className={styles.title}>
                  <Title level={isMobileView ? 4 : 2}>
                    {swimlane.title}
                    {displayTotalCount && <span className={styles.totalCount}>({swimlane.totalCount})</span>}
                    {swimlane.titleSuffix}
                  </Title>
                  {(swimlane.totalCount > maxNumberOfItems) && (
                    <div className={styles.moreContainer}>
                      <Href to={allItemsHref}>{getGenderFormatMessage('show_all')}</Href>
                    </div>
                  )}
                </div>
              )}
              {hasDescription && (
                <div className={styles.description}>
                  {isAiPage ? (
                    <>
                      <div>{swimlane.description.need}</div>
                      <div>{swimlane.description.tool}</div>
                    </>
                  ) : (
                    swimlane.description
                  )}
                </div>
              )}
              <SwimLane items={items} maxNumberOfItems={maxNumberOfItems} slideBy={isMobile ? 'scroll' : 'item'} />
            </div>
          )}
        </React.Fragment>
      );
    };

    return (
      <>
        {!!swimlanes && swimlanes.length > 0 && swimlanes.slice(0, randomBannerPosition).map(createSwimlane)}
        {hasRandomBanner && pageKey && <RandomTextImageBanner pageKey={pageKey} className={`${styles.swimlane} ${className || ''}`} />}
        {!!swimlanes && swimlanes.length > 0 && swimlanes.slice(randomBannerPosition).map(createSwimlane)}
      </>
    );
  }
);

SwimlaneList.propTypes = {
  swimlanes: PropTypes.arrayOf(
    PropTypes.shape({
      itemTypes: PropTypes.string,
      title: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
      allItemsHref: PropTypes.string,
      titleSuffix: PropTypes.element
    })
  ),
  maxNumberOfItems: PropTypes.number,
  viewKey: PropTypes.string,
  viewValue: PropTypes.string,
  className: PropTypes.string,
  showAll: PropTypes.bool,
  hasDescription: PropTypes.bool,
};
SwimlaneList.displayName = 'SwimlaneList';
export default SwimlaneList;
