import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TruncateMarkup from 'react-truncate-markup';

import AuthorizationMessage from './AuthorizationMessage';
import Icon from '../../Icon';
import Button from '../../Button';
import FavoritesButton from '../../FavoritesButton';
import Tooltip from '../../Tooltip';
import StuckMenu from '../../StuckMenu';
import DropDown from '../../DropDown';
import Href from '../../Href';
import { useGenderFormatMessage, useItem } from '../../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { setLoginData } from '../../../state/ui';
import { UiContext } from '../../../contexts';
import { CacheProvider } from '../../../utils/cacheProvider';
import { safelyGetInnerHTML } from '../../../utils/html';

import styles from '../Preview.module.scss';

const ContentHeader = ({
  type,
  itemParentCourses,
  hasAudiences,
  title,
  contextURL,
  isAllDisciplines,
  professions,
  grades,
  gradesTitle,
  duration,
  durationLabel,
  isCourse,
  chapters,
  summary,
  isInFavorites,
  onOpenClick,
  openLabel,
  openButtonType = 'line',
  marketingUrl,
  onAttachClick,
  onShareClick,
  onFavoritesClick,
  documentId,
  loItemInfo = { isLoItem: false, isLoReady: false, setIsInLoPreview: function () { }, isInLoPreview: false },
  hideButtons,
  itemDimensions
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { getGenderFormatMessage, getGenderFormatMessagePlain } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const [{ }, updateUiData] = useContext(UiContext);
  const { getItemInfo } = useItem();
  const [summaryLinesCount, setSummaryLinesCount] = useState(3);
  const [authorization, setAuthorization] = useState({ isLoadingData: true, item: false, parent: false });
  const [authorizationMessageContent, setAuthorizationMessageContent] = useState('');
  const [authorizationMessageOpenText, setAuthorizationMessageOpenText] = useState('');
  const [audiences, setAudiences] = useState(null);
  const [purchaseUrl, setPurchaseUrl] = useState(getGenderFormatMessage('shop_url'));
  const { isGuest, itemsFreeStatus, itemsAuthorizationStatus, activities, schoolId } = useSelector(({ user, items }) => ({
    isGuest: !user.info?.userId,
    itemsFreeStatus: items.freeStatus,
    itemsAuthorizationStatus: items.authorizationStatus,
    activities: user.activities,
    schoolId: user.lastSchool?.externalSchoolId ? user.lastSchool.externalSchoolId.toLocaleUpperCase() : ''
  }));
  const showAttachBtn = !itemDimensions?.audiences?.teachers;

  useEffect(() => {
    if (marketingUrl) {
      setPurchaseUrl(marketingUrl);
    }
  }, [marketingUrl]);

  const readMoreLabel = <b>{getGenderFormatMessage('read_more')}...</b>;

  const handleGuestClick = () => {
    dispatch(setLoginData({ isOpen: true, isPurchase: true, purchaseUrl }));
  };

  const handleOpenClick = event => {
    event.stopPropagation();
    if (isGuest && !itemsFreeStatus[documentId]) {
      handleGuestClick();
    } else {
      onOpenClick();
    }
  };

  const handlePurchaseClick = event => {
    event.stopPropagation();
    window.open(purchaseUrl);
  };

  const handleAttachClick = event => {
    event.stopPropagation();
    if (isGuest) {
      handleGuestClick();
    } else {
      onAttachClick();
    }
  };

  const handleShareClick = event => {
    event.stopPropagation();
    onShareClick();
  };

  const handleFavoritesClick = event => {
    event.stopPropagation();
    if (isGuest) {
      handleGuestClick();
    } else {
      onFavoritesClick();
    }
  };

  const handleQuickPreviewClick = event => {
    event.stopPropagation();
    if (isGuest) {
      handleGuestClick();
    } else {
      loItemInfo.setIsInLoPreview(!loItemInfo?.isInLoPreview);
      CacheProvider.set('lo_preview_button_animation_done', '1');
    }
  };

  useEffect(() => {
    if (itemsAuthorizationStatus.hasOwnProperty(documentId)) {
      if (itemParentCourses?.length > 0 && itemParentCourses[0].parentContext === 'course') {
        getItemInfo(itemParentCourses[0].parentId).then(parentItem => {
          const userActivities = activities?.find((activity) => activity.externalId === parentItem.externalContextId && activity.schoolProfileId && activity.schoolProfileId.toLocaleUpperCase() === schoolId);
          setAudiences(userActivities?.additionalInfo?.audiences?.filter(audience => !audience.archiveYear));
          if (itemsAuthorizationStatus[parentItem.externalContextId]) {
            setAuthorization({ isLoadingData: false, item: itemsAuthorizationStatus[documentId], parent: itemsAuthorizationStatus[parentItem.externalContextId] });
          }
        });
      } else {
        setAuthorization({ isLoadingData: false, item: itemsAuthorizationStatus[documentId], parent: true });
      }
    }
  }, [activities, documentId, getItemInfo, itemParentCourses, itemParentCourses?.length, itemsAuthorizationStatus, schoolId]);

  useEffect(() => {
    if (!authorization.isLoadingData) {
      if (!authorization.item || !authorization.parent) {
        if (type !== 'Lo') {
          setAuthorizationMessageOpenText(getGenderFormatMessage(`${type.toLowerCase()}_peek`));
        }
        if (itemParentCourses?.length > 0) {
          //setPurchaseUrl(`/${intl.locale}/item/${itemParentCourses[0].parentId}`);
          if (!authorization.item) {
            setAuthorizationMessageContent(getGenderFormatMessagePlain('loAndCourse_is_unautorized').replace('X', itemParentCourses[0].parentName));
          } else {
            setAuthorizationMessageContent(getGenderFormatMessagePlain('loCourse_is_unautorized').replace('X', itemParentCourses[0].parentName));
          }
        } else {
          //setPurchaseUrl(marketingUrl)
          setAuthorizationMessageContent(getGenderFormatMessage(`${type.toLowerCase()}_is_unautorized`));
        }
      } else {
        if (type === 'Lo' && itemParentCourses?.length > 0) {
          if (audiences?.length > 0) {
            setAuthorizationMessageContent(getGenderFormatMessagePlain('loAndCourseWithAudience_is_autorized').replace('X', itemParentCourses[0].parentName));
          } else {
            setAuthorizationMessageContent(getGenderFormatMessagePlain('loAndCourseWithoutAudience_is_autorized').replace('X', itemParentCourses[0].parentName));
            setAuthorizationMessageOpenText(getGenderFormatMessage('create_env'))
          }
        } else if (type === 'Course' && !hasAudiences) {
          setAuthorizationMessageContent(getGenderFormatMessage('course_no_enviroments'));
          setAuthorizationMessageOpenText(getGenderFormatMessage('create_env'));
        }
      }
    }
  }, [audiences?.length, authorization.isLoadingData, authorization.item, authorization.parent, getGenderFormatMessage, getGenderFormatMessagePlain, hasAudiences, itemParentCourses, type]);


  if (isMobileView) {
    return (
      <>
        <StuckMenu
          type="itemHeader"
          stuckUpClassName={styles.stuckUpClassName}
          stuckMenuClassName={`${styles.stuckMenuClassName} ${styles.stuckMenuClassName_title}`}
          layoutCenterClassName={styles.layoutCenterClassName}
          layoutTop={
            <>
              <h2 className={styles.title}>{title}</h2>
              <div className={styles.titleMarker} data-scrolling-handler-element="itemHeaderTopMarker"></div>
            </>
          }
        />
        {/* profession */}
        <div className={styles.row} style={{ marginTop: '0.5rem' }}>
          <div className={styles.professions}>
            {(professions || isAllDisciplines) && (
              <>
                <strong>{isAllDisciplines ? `${getGenderFormatMessage('all_disciplines')}` : `${getGenderFormatMessage('profession')}:`}</strong>
                {professions && <span>{professions}</span>}
              </>
            )}
          </div>
        </div>
        {/* grades, duration */}
        <div className={styles.row}>
          <div className={styles.grades}>
            <strong className={styles.strongTitle}>{gradesTitle || getGenderFormatMessage('grades')}:</strong>
            <span>{grades}</span>
          </div>
          {duration > 0 && (
            <div className={styles.duration}>
              <Icon icon="clock" width="0.8125rem" height="0.95rem" />
              {isCourse && duration === 999
                ? getGenderFormatMessage('yearlyCourse')
                : `${duration > 120 ? '120+' : duration} ${getGenderFormatMessage(durationLabel || 'minutes')}`}
            </div>
          )}
          {chapters && (
            <div className={styles.duration}>
              {chapters} {getGenderFormatMessage('chapters')}
            </div>
          )}
          <div className={`${styles.buttons} ${styles.buttonsEnd}`}>
            <div className={styles.spacedButton}>
              <Tooltip content={getGenderFormatMessage('share')}>
                <Button type="none" onClick={handleShareClick} className={`${styles.roundButton} ${styles.share} item_share`} category="preview" label="share" aria-label={getGenderFormatMessage('share')} >
                  <Icon icon="share" width={24.5} height={19.3} title={getGenderFormatMessage('share')} />
                </Button>
              </Tooltip>
            </div>
            <FavoritesButton
              addToFavoritesLabel={getGenderFormatMessage('add_to_favorites')}
              removeFromFavoritesLabel={getGenderFormatMessage('remove_from_favorites')}
              className={styles.roundButton + ' item_favorite'}
              isInFavorites={isInFavorites}
              category="preview"
              label="favorite"
              onClick={handleFavoritesClick}
              ariaLabel={isInFavorites ? getGenderFormatMessage('remove_from_favorites') : getGenderFormatMessage('add_to_favorites')}
            />
          </div>
        </div>
        <div className={`${styles.buttons} ${styles.buttonsBottom}`}>
          <div className={styles.spacedButton}>
            <Button
              onClick={handleOpenClick}
              type={openButtonType}
              size="medium"
              className={styles.headerButton + ' item_open'}
              category="preview"
              label="open"
              aria-label={getGenderFormatMessage(openLabel || 'open')}
              disabled={!authorization.item && !isGuest}
            >
              {getGenderFormatMessage(openLabel || 'open')}
            </Button>
          </div>
          {onAttachClick && (
            <Button
              onClick={handleAttachClick}
              type="primary"
              size="medium"
              className={styles.headerButton + ' item_assign'}
              category="preview"
              label="assign"
              aria-label={getGenderFormatMessage('attach')}
              disabled={!authorization.item && !isGuest}
            >
              {getGenderFormatMessage('attach')}
            </Button>
          )}
        </div>
        {/*  AuthorizationMessage */}
        {!isGuest && !authorization.isLoadingData && authorizationMessageContent && (
          <AuthorizationMessage
            message={authorizationMessageContent}
            isOpenBtn={Boolean(authorizationMessageOpenText)}
            openText={authorizationMessageOpenText}
            onOpenClick={handleOpenClick}
            isPurchaseBtn={Boolean(purchaseUrl) && (!authorization.item || !authorization.parent)}
            purchaseText={getGenderFormatMessage(`${type.toLowerCase()}_purchase`)}
            onPurchaseClick={handlePurchaseClick}
          > {audiences && audiences.length > 0 && <div>
            <br /><b>{getGenderFormatMessage('assign_activity_in_env')}</b><br /><br />
            <DropDown label={getGenderFormatMessage('choose_env')} buttonClassName={styles.messageSelectButton} listClassName={styles.messageSlectList}>
              <ul>
                {audiences.map((audience) => (
                  <li key={audience.id} className={styles.messageSelectItem}>
                    <Href to={`${contextURL}?audience=${audience.id}`} target="_blank" onClick={() => { updateUiData({ dropBoxContent: null }); }}>{audience.name}</Href>
                  </li>
                ))}
              </ul>
            </DropDown>
          </div>}
          </AuthorizationMessage>
        )}
      </>
    );
  }

  const isRTL = intl && (intl.locale === 'he' || intl.locale === 'ar');

  return (
    <>
      <h2>{title}</h2>
      {/* profession, grades, duration */}
      <div className={styles.row}>
        <div className={styles.professions}>
          {(professions || isAllDisciplines) && (
            <>
              <strong>{isAllDisciplines ? `${getGenderFormatMessage('all_disciplines')}` : `${getGenderFormatMessage('profession')}:`}</strong>
              {professions && <span>{professions}</span>}
            </>
          )}
        </div>
        <div className={styles.grades}>
          <strong>{gradesTitle || getGenderFormatMessage('grades')}:</strong>
          <span>{grades}</span>
        </div>
        {duration > 0 && (
          <div className={styles.duration}>
            <Icon icon="clock" width="0.8125rem" height="0.95rem" />
            {isCourse && duration === 999
              ? getGenderFormatMessage('yearlyCourse')
              : `${duration > 120 ? '120+' : duration} ${getGenderFormatMessage(durationLabel || 'minutes')}`}
          </div>
        )}
        {chapters && (
          <div className={styles.duration}>
            {chapters} {getGenderFormatMessage('chapters')}
          </div>
        )}
      </div>
      {/*  buttons */}
      {!hideButtons && (
        <div className={styles.row}>
          <div className={`${styles.buttons} ${styles.buttonsStart}`}>
            <div className={styles.spacedButton}>
              <Button
                onClick={handleOpenClick}
                type={openButtonType}
                size="medium"
                className={styles.headerButton + ' item_open'}
                category="preview"
                label="open"
                aria-label={getGenderFormatMessage(openLabel || 'open')}
                disabled={!authorization.item && !isGuest}
              >
                {getGenderFormatMessage(openLabel || 'open')}
              </Button>
            </div>
            {onAttachClick && showAttachBtn && (
              <Button
                onClick={handleAttachClick}
                type="primary"
                size="medium"
                className={styles.headerButton + ' item_assign'}
                category="preview"
                label="assign"
                aria-label={getGenderFormatMessage('attach')}
                disabled={!authorization.item && !isGuest}
              >
                {getGenderFormatMessage('attach')}
              </Button>
            )}
          </div>
          <div className={styles.buttons}>
            {loItemInfo?.isLoItem && (
              <div className={styles.spacedButton}>
                <Tooltip content={<div className={styles.tooltipContent}>{getGenderFormatMessage('preview_item_tooltip')}</div>}>
                  <Button
                    disabled={loItemInfo?.isLoReady ? false : true}
                    type="none"
                    className={`${styles.roundButton} ${styles.quickPreviewButton} ${loItemInfo?.isLoReady && !CacheProvider.get('lo_preview_button_animation_done') ? styles.quickPreviewButtonAnimated : ''
                      }`}
                    onClick={handleQuickPreviewClick}
                    category="preview"
                    label={loItemInfo?.isInLoPreview ? 'backFromQuickView' : 'quickView'}
                    aria-label={getGenderFormatMessage('preview_item')}
                  >
                    <div className={styles.quickPreviewIconContainer}>
                      <Icon icon="eye" width={24.5} height={19.3} title={getGenderFormatMessage('preview_item')} />
                    </div>
                    <div className={`${styles.quickPreviweText} ${loItemInfo?.isLoReady ? styles.quickPreviweTextAnimated : ''}`}>
                      {getGenderFormatMessage('preview_item')}
                    </div>
                  </Button>
                </Tooltip>
              </div>
            )}
            <div className={styles.spacedButton}>
              <Tooltip content={<div className={styles.tooltipContent}>{getGenderFormatMessage('share')}</div>}>
                <Button
                  type="none"
                  onClick={handleShareClick}
                  className={`${styles.roundButton} ${styles.share} item_share`}
                  category="preview"
                  label="share"
                  aria-label={getGenderFormatMessage('share')}
                  optionalData={{
                    objectAdditionalInformation: {
                      itemDocumentID: documentId ? documentId : ''
                    }
                  }}
                >
                  <Icon icon="share" width={24.5} height={19.3} title={getGenderFormatMessage('share')} />
                </Button>
              </Tooltip>
            </div>
            <FavoritesButton
              addToFavoritesLabel={getGenderFormatMessage('add_to_favorites')}
              removeFromFavoritesLabel={getGenderFormatMessage('remove_from_favorites')}
              className={styles.roundButton + ' item_favorite'}
              isInFavorites={isInFavorites}
              category="preview"
              label="favorite"
              onClick={handleFavoritesClick}
              ariaLabel={isInFavorites ? getGenderFormatMessage('remove_from_favorites') : getGenderFormatMessage('add_to_favorites')}
            />
          </div>
        </div>
      )}
      {/*  AuthorizationMessage */}
      {!isGuest && !authorization.isLoadingData && authorizationMessageContent && (
        <AuthorizationMessage
          message={authorizationMessageContent}
          isOpenBtn={Boolean(authorizationMessageOpenText)}
          openText={authorizationMessageOpenText}
          onOpenClick={handleOpenClick}
          isPurchaseBtn={Boolean(purchaseUrl) && (!authorization.item || !authorization.parent)}
          purchaseText={getGenderFormatMessage(`${type.toLowerCase()}_purchase`)}
          onPurchaseClick={handlePurchaseClick}
        > {audiences && audiences.length > 0 && <div>
          <br /><b>{getGenderFormatMessage('assign_activity_in_env')}</b><br /><br />
          <DropDown label={getGenderFormatMessage('choose_env')} buttonClassName={styles.messageSelectButton} listClassName={styles.messageSlectList}>
            <ul>
              {audiences.map((audience) => (
                <li key={audience.id} className={styles.messageSelectItem}>
                  <Href to={`${contextURL}?audience=${audience.id}`} target="_blank" onClick={() => { updateUiData({ dropBoxContent: null }); }}>{audience.name}</Href>
                </li>
              ))}
            </ul>
          </DropDown>
        </div>}
        </AuthorizationMessage>
      )}
      {/*  summary */}
      <p className={styles.row}>
        <TruncateMarkup
          lines={summaryLinesCount}
          ellipsis={
            <span>...
              <Button
                aria-label={readMoreLabel}
                style={isRTL ? { marginRight: 8 } : { marginLeft: 8 }}
                onClick={() => setSummaryLinesCount(999)}
                type="link">
                {readMoreLabel}
              </Button>
            </span>
          }>
          <div>{safelyGetInnerHTML(summary)}</div>
        </TruncateMarkup>
      </p>
    </>
  );
};

ContentHeader.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contextURL: PropTypes.string,
  itemParentCourses: PropTypes.array,
  hasAudiences: PropTypes.bool,
  professions: PropTypes.string.isRequired,
  grades: PropTypes.string.isRequired,
  gradesTitle: PropTypes.string,
  duration: PropTypes.number,
  durationLabel: PropTypes.string,
  isCourse: PropTypes.bool,
  chapters: PropTypes.number,
  summary: PropTypes.string.isRequired,
  isInFavorites: PropTypes.bool,
  onOpenClick: PropTypes.func.isRequired,
  openLabel: PropTypes.string,
  openButtonType: PropTypes.string,
  marketingUrl: PropTypes.string,
  onAttachClick: PropTypes.func,
  onShareClick: PropTypes.func.isRequired,
  onFavoritesClick: PropTypes.func.isRequired,
  documentId: PropTypes.string
};

export default ContentHeader;
