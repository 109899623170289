import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { lo } from '../../utils/urls';
import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { loadScripts } from '../../utils/resourceLoader';

import Icon from '../Icon';
import Button from '../Button';
import Tabs from '../Tabs';
import StuckMenu from '../StuckMenu';
import ContentHeader from './Partials/ContentHeader';
import SubjectsList from './Partials/SubjectsList';
import NanoCourseRecommendations from './Partials/NanoCourseRecommendations';

import styles from './Preview.module.scss';
import ReferencesList from './Partials/ReferencesList';

import { AttachedTag, NewTag } from '../Tag';
import { CacheProvider } from '../../utils/cacheProvider';
import { safelyGetInnerHTML } from '../../utils/html';

const LO_PLUGIN_URL = `${lo}/plugin/lo-plugin.js`;

const Item = ({
  itemId,
  documentId,
  language,
  activityType,
  activityTypeLabel,
  title,
  type,
  banner,
  professions,
  isAllDisciplines,
  grades,
  duration,
  yearCycles,
  summary,
  syllabus,
  additionalInfo,
  skills,
  itemDimensions,
  pedagogicValues,
  references,
  totalNumOfQuestions,
  numOfAutomaticQuestions,
  itemTypes,
  isInFavorites,
  isMobileSupported,
  isLoItem,
  isNew,
  isCourse,
  onOpenClick,
  onShareClick,
  onFavoritesClick,
  onBannerError,
  onBannerLoad,
  canShowNanoCourseRecommendationsAnimation,
  nanoCourseRecommendationsItems,
  hideButtons
}) => {
  const [nanoContent, setNanoContent] = useState([]);

  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const { search } = useLocation();

  const [isReady, setIsReady] = useState(false);
  const [isLoReady, setIsLoReady] = useState(false);
  const [isInLoPreview, setIsInLoPreview] = useState(false);
  const loContainerRef = useRef();
  const [selectedTab, setSelectedTab] = useState('summary');

  const [showNanoCourseRecommendationsAnimation, setShowNanoCourseRecommendationsAnimation] = useState(false);
  const loMarketingUrl = getGenderFormatMessage('lo_purchase_url');

  const { isAttached } = useSelector(({ user }) => {
    return {
      isAttached: Boolean(
        user.activities.find(
          activity =>
            documentId &&
            activity.externalId?.toLocaleLowerCase() === documentId.toLocaleLowerCase() &&
            activity.schoolProfileId?.toLocaleLowerCase() === user.lastSchool?.externalSchoolId.toLocaleLowerCase()
        )
      )
    };
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const isFavoriteFormOpen = urlParams.get('fav') !== null;

    if (!isFavoriteFormOpen) {
      loadScripts([LO_PLUGIN_URL]).then(() => {
        setIsReady(true);
        if (loContainerRef.current) {
          window.cet.lo.plugin.init(loContainerRef.current, {}, {}).then(
            () => {
              const connection = {
                documentId,
                languageId: language
              };
              const viewSettings = {
                childDocumentId: null,
                menubarType: 'nobar',
                hideCloseButton: true,
                hideLogo: true,
                hideCreateTaskButton: true,
                hideStudioButtons: true,
                hideViewAsMenu: true,
                showLoginButton: false,
                showLogoutButton: true,
                hideShareLinks: true,
                isAdaptive: false,
                gotoParams: null
              };

              window.cet.lo.plugin.loadDocument(connection, viewSettings);
              window.cet.lo.plugin.loadDocument(connection, viewSettings).then(() => setIsLoReady(true));
            },
            () => {
              console.log('LO init failed!');
            }
          );
        }
      });
    }
  }, [documentId, language, loContainerRef, search, isMobileView]);

  useEffect(() => {
    let nanoContentData = [];
    if (additionalInfo?.nanoData?.nanoContent) {
      nanoContentData = additionalInfo.nanoData.nanoContent
        .replace(/(\r\n|\n|\r)/gm, ';')
        .split(';')
        .map(item => item.trim());
    }
    setNanoContent(nanoContentData);
  }, [additionalInfo]);

  useEffect(() => {
    if (
      canShowNanoCourseRecommendationsAnimation &&
      nanoCourseRecommendationsItems &&
      nanoCourseRecommendationsItems.length > 0 &&
      !CacheProvider.get('PreviewNanoCourseRecommendationsAnimationDone')
    ) {
      setShowNanoCourseRecommendationsAnimation(true);
      CacheProvider.set('PreviewNanoCourseRecommendationsAnimationDone', '1');
    }
  }, [nanoCourseRecommendationsItems, canShowNanoCourseRecommendationsAnimation]);

  if (!isReady) return null;

  const nanoCourseRecommendations = (
    <NanoCourseRecommendations title={getGenderFormatMessage('preview_nanoCourseRecommendations_title')} items={nanoCourseRecommendationsItems} />
  );

  if (isMobileView) {
    let tabs = [
      { value: 'summary', text: getGenderFormatMessage('preview_mobile_lo_summary'), totalCount: -1 },
      { value: 'syllabus', text: getGenderFormatMessage('preview_mobile_lo_syllabus'), totalCount: -1 }
    ];
    if (skills?.length > 0 || pedagogicValues?.length > 0) {
      tabs.push({ value: 'skills', text: getGenderFormatMessage('preview_mobile_lo_skills'), totalCount: -1 });
    }
    return (
      <div className={styles.mobileContainer} data-scrolling-handler-element="scroller">
        <div className={styles.topMarker} data-scrolling-handler-element="itemTopMarker"></div>
        <div className={`${styles.banner} ${isInLoPreview ? styles.loPreview : ''}`}>
          <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={348} />
          <div className={styles.tags}>
            {isNew && <NewTag label={getGenderFormatMessage('new')} angle="107deg" withSpout={false} />}
            {isAttached && <AttachedTag label={getGenderFormatMessage('attached')} angle="107deg" withSpout={false} />}
          </div>
        </div>
        <div className={styles.content}>
          <ContentHeader
            type={type}
            title={title}
            isAllDisciplines={isAllDisciplines}
            professions={professions}
            grades={grades}
            gradesTitle={getGenderFormatMessage('gradesTitle_forTeachers')}
            duration={duration}
            durationLabel={isCourse ? 'hours' : 'minutes'}
            isCourse={isCourse}
            isInFavorites={isInFavorites}
            onOpenClick={onOpenClick}
            onShareClick={onShareClick}
            onFavoritesClick={onFavoritesClick}
            marketingUrl={loMarketingUrl}
            hideButtons={hideButtons}
            documentId={documentId}
            itemDimensions={itemDimensions}
          />
          {yearCycles && <div className={styles.yearCycles}>{yearCycles}</div>}
          <StuckMenu
            type="item"
            stuckUpClassName={styles.stuckUpClassName}
            stuckMenuClassName={styles.stuckMenuClassName}
            layoutCenterClassName={styles.layoutCenterClassName}
            layoutTop={
              <>
                <div className={styles.tabs}>
                  <Tabs
                    tabs={tabs}
                    addAllTab={false}
                    initToCenter={true}
                    activeTabKey={selectedTab}
                    onTabClick={({ value }) => {
                      setSelectedTab(value);
                    }}
                  />
                </div>
              </>
            }
          />
          <div>
            {selectedTab === 'summary' && (
              <>
                {/* summary */}
                <div className={styles.row}>{safelyGetInnerHTML(summary)}</div>
                {/*  itemTypes */}
                <div className={styles.row}>
                  {itemTypes?.map((itemType, index) => (
                    <div key={index} className={styles.itemType}>
                      <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
                      <div>{itemType.title}</div>
                    </div>
                  ))}
                </div>
                {/*  activity type, automatic evaluated questions */}
                {(activityType.startsWith('evaluation') || totalNumOfQuestions > 0) && (
                  <div className={styles.bordered}>
                    {activityType.startsWith('evaluation') && (
                      <div className={styles.activityType}>
                        <strong className={styles.label}>{getGenderFormatMessage('activity_type')}:</strong> {activityTypeLabel}
                      </div>
                    )}
                    {totalNumOfQuestions > 0 && (
                      <div className={styles.automaticQuestions}>
                        <strong className={styles.label}>{getGenderFormatMessage('automatic_evaluated_questions')}:</strong> <b>{numOfAutomaticQuestions}</b>{' '}
                        {getGenderFormatMessage('out_of')} {totalNumOfQuestions}
                      </div>
                    )}
                  </div>
                )}

                {/* nanoContent */}
                {nanoContent?.length > 0 && (
                  <div className={styles.courseContent}>
                    <div className={styles.courseContentTitle}>{getGenderFormatMessage('preview_nano_content_title')}</div>
                    {nanoContent.map((item, i) => (
                      <div key={`courseContentItem--${i}`} className={styles.courseContentItem}>
                        <Icon icon="icon_v" />
                        {item}
                      </div>
                    ))}
                  </div>
                )}

                {/* references */}
                {references &&
                  Object.keys(references).map(source => {
                    return (
                      <div key={`reference_${source}`} className={`${styles.references} ${styles.margined}`}>
                        <ReferencesList
                          title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage(source)}`}
                          list={references[source]}
                          startLinesCount={references[source] ? references[source].length : 3}
                        />
                      </div>
                    );
                  })}
              </>
            )}

            {selectedTab === 'syllabus' && syllabus?.length > 0 && (
              <>
                {/* syllabus */}
                <div className={styles.bordered}>
                  <SubjectsList list={syllabus} startLinesCount={syllabus ? syllabus.length : 3} />
                </div>
              </>
            )}

            {selectedTab === 'skills' && skills?.length > 0 && (
              <>
                {/* skills, pedagogic values */}
                {(skills?.length > 0 || pedagogicValues?.length > 0) && (
                  <>
                    <div className={styles.bordered}>
                      <SubjectsList list={skills} startLinesCount={skills ? skills.length : 3} title={getGenderFormatMessage('skills')} />

                      {/* pedagogic values */}
                      {pedagogicValues?.length > 0 && (
                        <div className={styles.margined}>
                          <SubjectsList
                            list={pedagogicValues}
                            startLinesCount={pedagogicValues ? pedagogicValues.length : 3}
                            title={getGenderFormatMessage('pedagogic_values')}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}

            {/* keep recommendations in summary tab only */}
            {selectedTab === 'summary' && nanoCourseRecommendations}
          </div>
        </div>
      </div>
    );
  }

  const getPreviewButtonClassName = () => {
    let classNameList = [styles.previewButton, 'preview_lo'];

    if (isInLoPreview) {
      classNameList.push(styles['previewButton--open']);
    }

    if (!CacheProvider.get('lo_preview_button_animation_done')) {
      classNameList.push(styles['previewButton--animate']);
    }

    return classNameList.join(' ');
  };

  return (
    <div>
      {!isInLoPreview && (
        <div className={styles.tags}>
          {isNew && <NewTag label={getGenderFormatMessage('new')} />}
          {isAttached && <AttachedTag label={getGenderFormatMessage('attached')} />}
        </div>
      )}
      {isInLoPreview && (
        <Button
          type="none"
          className={getPreviewButtonClassName()}
          onClick={() => {
            CacheProvider.set('lo_preview_button_animation_done', '1');
            setIsInLoPreview(!isInLoPreview);
          }}
          category="preview"
          label={isInLoPreview ? 'backFromQuickView' : 'quickView'}
        >
          <div>
            <Icon
              icon={'eye'}
              className={isInLoPreview ? styles.arrow : styles.eye}
              title={getGenderFormatMessage('preview_item')}
              width={isInLoPreview ? 14 : 24}
              height={isInLoPreview ? 14 : 24}
            />
            {getGenderFormatMessage('preview_item')}
          </div>
          <div>{getGenderFormatMessage('back')}</div>
        </Button>
      )}
      <div className={`${styles.banner} ${isInLoPreview ? styles.loPreview : ''}`}>
        {isLoItem && <div ref={loContainerRef} className={styles.loContainer} />}
        <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={348} />
      </div>
      <div className={styles.content}>
        <ContentHeader
          type={type}
          title={title}
          professions={professions}
          isAllDisciplines={isAllDisciplines}
          grades={grades}
          gradesTitle={getGenderFormatMessage('gradesTitle_forTeachers')}
          duration={duration}
          durationLabel={isCourse ? 'hours' : 'minutes'}
          isCourse={isCourse}
          summary={summary}
          isInFavorites={isInFavorites}
          onOpenClick={onOpenClick}
          onShareClick={onShareClick}
          onFavoritesClick={onFavoritesClick}
          marketingUrl={loMarketingUrl}
          documentId={documentId}
          loItemInfo={{ isLoItem, isLoReady, isInLoPreview, setIsInLoPreview }}
          hideButtons={hideButtons}
          itemDimensions={itemDimensions}
        />
        {/*  itemTypes */}
        <div className={styles.row}>
          {itemTypes?.map((itemType, index) => (
            <div key={index} className={styles.itemType}>
              <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
              <div>{itemType.title}</div>
            </div>
          ))}
        </div>
        {/* nanoContent */}
        {nanoContent?.length > 0 && (
          <div className={styles.courseContent}>
            <div className={styles.courseContentTitle}>{getGenderFormatMessage('preview_nano_content_title')}</div>
            {nanoContent.map((item, i) => (
              <div key={`nanoContentItem--${i}`} className={styles.courseContentItem}>
                <Icon icon="icon_v" />
                {item}
              </div>
            ))}
          </div>
        )}

        {/*  activity type, automatic evaluated questions */}
        {(activityType.startsWith('evaluation') || totalNumOfQuestions > 0) && (
          <div className={styles.bordered}>
            {activityType.startsWith('evaluation') && (
              <div className={styles.activityType}>
                <strong className={styles.label}>{getGenderFormatMessage('activity_type')}:</strong> {activityTypeLabel}
              </div>
            )}
            {totalNumOfQuestions > 0 && (
              <div className={styles.automaticQuestions}>
                <strong className={styles.label}>{getGenderFormatMessage('automatic_evaluated_questions')}:</strong> <b>{numOfAutomaticQuestions}</b>{' '}
                {getGenderFormatMessage('out_of')} {totalNumOfQuestions}
              </div>
            )}
          </div>
        )}

        {/* syllabus */}
        {syllabus?.length > 0 && (
          <div className={styles.bordered}>
            <SubjectsList list={syllabus} title={getGenderFormatMessage('syllabus_subject')} />
          </div>
        )}

        {/* skills, pedagogic values */}
        {(skills?.length > 0 || pedagogicValues?.length > 0) && (
          <>
            <div className={styles.bordered}>
              <SubjectsList list={skills} title={getGenderFormatMessage('skills')} />

              {/* pedagogic values */}
              {pedagogicValues?.length > 0 && (
                <div className={styles.margined}>
                  <SubjectsList list={pedagogicValues} title={getGenderFormatMessage('pedagogic_values')} />
                </div>
              )}
            </div>
          </>
        )}

        {/* references */}
        {references &&
          Object.keys(references).map(source => {
            return (
              <div key={`reference_${source}`} className={`${styles.references} ${styles.margined}`}>
                <ReferencesList title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage(source)}`} list={references[source]} />
              </div>
            );
          })}

        {/* Temporary removed nanoCourseRecommendations (remove the "false" keyword to enable again) */}
        {false && nanoCourseRecommendationsItems && nanoCourseRecommendationsItems.length > 0 && (
          <div className={`${styles.bordered} ${styles.overflowVisible}`}>{nanoCourseRecommendations}</div>
        )}
      </div>
    </div>
  );
};

Item.propTypes = {
  documentId: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  activityTypeLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  banner: PropTypes.string.isRequired,
  professions: PropTypes.string.isRequired,
  grades: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  summary: PropTypes.string.isRequired,
  itemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  syllabus: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string,
      subSubject: PropTypes.string,
      details: PropTypes.string
    })
  ),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.string,
      sub: PropTypes.string,
      details: PropTypes.string
    })
  ),
  pedagogicValues: PropTypes.arrayOf(
    PropTypes.shape({
      main: PropTypes.string,
      details: PropTypes.string
    })
  ),
  references: PropTypes.shape({
    books: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
      })
    ),
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
      })
    ),
    sequences: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired
      })
    )
  }),
  totalNumOfQuestions: PropTypes.number.isRequired,
  numOfAutomaticQuestions: PropTypes.number.isRequired,
  isInFavorites: PropTypes.bool,
  isMobileSupported: PropTypes.bool,
  isLoItem: PropTypes.bool,
  isNew: PropTypes.bool,
  isCourse: PropTypes.bool,
  onOpenClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onFavoritesClick: PropTypes.func.isRequired,
  onBannerError: PropTypes.func
};

export default Item;
