import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SwimlaneList from '../../SwimlaneList';
import { useSearch, useGenderFormatMessage } from '../../../hooks';
import Button from '../../Button';

import styles from '../Preview.module.scss';
import { safelyGetInnerHTML } from '../../../utils/html';

export const useNanoCourseRecommendations = (itemDimensions, itemId) => {
    const [items, setItems] = useState([]);
    const { isLogin, lastSchool, dimensions } = useSelector(({ user, dimensions }) => {
        return {
            isLogin: user && user.info && user.info.userId,
            lastSchool: user.lastSchool,
            dimensions
        };
    });
    const dispatchSearch = useSearch();
    const { skills, subskills, generalSkills, generalSubskills, ageGrades: itemAgeGrades, disciplines: itemDisciplines, pedagogicValues } = itemDimensions || {};

    useEffect(() => {
        let isActive = true;

        if (dispatchSearch && dimensions && dimensions.dimensionsMapper && isLogin && lastSchool) {
            const teacherAgeGradesMapper = {};
            const teacherDisciplinesMapper = {};

            if (lastSchool.teacherAgeGrades && lastSchool.teacherAgeGrades.length > 0) {
                for (let i = 0; i < lastSchool.teacherAgeGrades.length; i++) {
                    const teacherAgeGrade = lastSchool.teacherAgeGrades[i];
                    if (!teacherAgeGradesMapper[teacherAgeGrade.ageGradeId]) {
                        teacherAgeGradesMapper[teacherAgeGrade.ageGradeId] = {};
                    }
                    if (teacherAgeGrade.disciplines && teacherAgeGrade.disciplines.length > 0) {
                        for (let j = 0; j < teacherAgeGrade.disciplines.length; j++) {
                            const discipline = teacherAgeGrade.disciplines[j];
                            teacherAgeGradesMapper[teacherAgeGrade.ageGradeId][discipline.id] = true;
                            teacherDisciplinesMapper[discipline.id] = true;
                        }
                    }
                }
            }

            const published = { isPublished: [true] };
            const nanoCourse = { activityTypes: ["nanoCourse"] };
            const cuttingAgeGrades = (itemAgeGrades ? Object.keys(itemAgeGrades) : []).filter(ageGrade => teacherAgeGradesMapper[ageGrade]);

            const getSkillsSearchParams = () => {
                const skillsSearchParams = [];
                const subSkillsIdsSearchParams = subskills ? Object.keys(subskills) : [];
                const subSkillsParentIdsSearchParams = dimensions.dimensionsMapper.subskills ? subSkillsIdsSearchParams.map(subSkillsId => dimensions.dimensionsMapper.subskills[subSkillsId] ? dimensions.dimensionsMapper.subskills[subSkillsId].parentId : '') : [];
                const skillsIdsSearchParams = (skills ? Object.keys(skills) : []).filter(skill => subSkillsParentIdsSearchParams.indexOf(skill) < 0);
                const generalSubskillsIdsSearchParams = generalSubskills ? Object.keys(generalSubskills) : [];
                const generalSubskillsParentIdsSearchParams = dimensions.dimensionsMapper.generalSubskills ? generalSubskillsIdsSearchParams.map(generalSubSkillsId => dimensions.dimensionsMapper.generalSubskills[generalSubSkillsId] ? dimensions.dimensionsMapper.generalSubskills[generalSubSkillsId].parentId : '') : [];
                const generalSkillsIdsSearchParams = (generalSkills ? Object.keys(generalSkills) : []).filter(generalSkill => generalSubskillsParentIdsSearchParams.indexOf(generalSkill) < 0);

                if (cuttingAgeGrades.length > 0) {
                    if (skillsIdsSearchParams.length) {
                        skillsSearchParams.push([
                            published,
                            nanoCourse,
                            { ageGrades: cuttingAgeGrades },
                            { skills: skillsIdsSearchParams },
                            { subActivityTypes: ["skills"] }
                        ])
                    }
                    if (subSkillsIdsSearchParams.length > 0) {
                        skillsSearchParams.push([
                            published,
                            nanoCourse,
                            { ageGrades: cuttingAgeGrades },
                            { subskills: subSkillsIdsSearchParams },
                            { subActivityTypes: ["skills"] }
                        ])
                    }
                    if (generalSkillsIdsSearchParams.length > 0) {
                        skillsSearchParams.push([
                            published,
                            nanoCourse,
                            { ageGrades: cuttingAgeGrades },
                            { generalSkills: generalSkillsIdsSearchParams },
                            { subActivityTypes: ["skills"] }
                        ])
                    }
                    if (generalSubskillsIdsSearchParams.length > 0) {
                        skillsSearchParams.push([
                            published,
                            nanoCourse,
                            { ageGrades: cuttingAgeGrades },
                            { generalSubskills: generalSubskillsIdsSearchParams },
                            { subActivityTypes: ["skills"] }
                        ])
                    }
                }

                return skillsSearchParams;
            };

            const getTeachingPracticesSearchParams = () => {
                const teachingPracticesSearchParams = [];
                if (cuttingAgeGrades.length > 0) {
                    teachingPracticesSearchParams.push([
                        published,
                        nanoCourse,
                        { ageGrades: cuttingAgeGrades },
                        { subActivityTypes: ["teaching_practices"] }
                    ]);
                }
                return teachingPracticesSearchParams;
            };

            const getKnowledgeSearchParams = () => {
                const knowledgeSearchParams = [];
                if (cuttingAgeGrades.length > 0) {
                    cuttingAgeGrades.forEach(ageGrade => {
                        const cuttingDisciplines = (itemDisciplines ? Object.keys(itemDisciplines) : []).filter(discipline => teacherAgeGradesMapper[ageGrade][discipline]);
                        if (cuttingDisciplines.length > 0) {
                            knowledgeSearchParams.push([
                                published,
                                nanoCourse,
                                { disciplines: cuttingDisciplines },
                                { ageGrades: [ageGrade] },
                                { subActivityTypes: ["knowledge"] }
                            ]);
                        }
                    });
                }
                return knowledgeSearchParams;
            };

            const getValuesSearchParams = () => {
                const valuesSearchParams = [];
                const pedagogicValuesIdsSearchParams = pedagogicValues ? Object.keys(pedagogicValues) : [];
                if (cuttingAgeGrades.length > 0 && pedagogicValuesIdsSearchParams.length > 0) {
                    valuesSearchParams.push([
                        published,
                        nanoCourse,
                        { ageGrades: cuttingAgeGrades },
                        { pedagogicValues: pedagogicValuesIdsSearchParams },
                        { subActivityTypes: ["values"] }
                    ]);
                }
                return valuesSearchParams;
            };

            const skillsSearchParams = getSkillsSearchParams();
            const teachingPracticesSearchParams = getTeachingPracticesSearchParams();
            const knowledgeSearchParams = getKnowledgeSearchParams();
            const valuesSearchParams = getValuesSearchParams();

            const searchParams = [
                ...skillsSearchParams,
                ...teachingPracticesSearchParams,
                ...knowledgeSearchParams,
                ...valuesSearchParams
            ];

            const search = async () => {
                const nanoCoursesResult = await dispatchSearch(searchParams, undefined, 10, undefined, undefined, 'parallelSearchFast');

                if (isActive && nanoCoursesResult?.length > 0) {
                    let itemsMapper = {};
                    if (itemId) {
                        itemsMapper[itemId] = true;
                    }
                    let data = [[], [], [], []];
                    let i = 0;

                    for (; i < skillsSearchParams.length && i < nanoCoursesResult.length; i++) {
                        if (nanoCoursesResult[i].length > 0 && nanoCoursesResult[i][0] && nanoCoursesResult[i][0].data && nanoCoursesResult[i][0].data.length > 0) {
                            for (let j = 0; j < nanoCoursesResult[i][0].data.length; j++) {
                                if (!itemsMapper[nanoCoursesResult[i][0].data[j].id]) {
                                    itemsMapper[nanoCoursesResult[i][0].data[j].id] = nanoCoursesResult[i][0].data[j];
                                    data[0].push(nanoCoursesResult[i][0].data[j]);
                                }
                            }
                        }
                    }

                    itemsMapper = {};
                    if (itemId) {
                        itemsMapper[itemId] = true;
                    }
                    for (; i < skillsSearchParams.length + teachingPracticesSearchParams.length && i < nanoCoursesResult.length; i++) {
                        if (nanoCoursesResult[i].length > 0 && nanoCoursesResult[i][0] && nanoCoursesResult[i][0].data && nanoCoursesResult[i][0].data.length > 0) {
                            for (let j = 0; j < nanoCoursesResult[i][0].data.length; j++) {
                                if (!itemsMapper[nanoCoursesResult[i][0].data[j].id]) {
                                    itemsMapper[nanoCoursesResult[i][0].data[j].id] = nanoCoursesResult[i][0].data[j];
                                    data[1].push(nanoCoursesResult[i][0].data[j]);
                                }
                            }
                        }
                    }

                    itemsMapper = {};
                    if (itemId) {
                        itemsMapper[itemId] = true;
                    }
                    for (; i < skillsSearchParams.length + teachingPracticesSearchParams.length + knowledgeSearchParams.length && i < nanoCoursesResult.length; i++) {
                        if (nanoCoursesResult[i].length > 0 && nanoCoursesResult[i][0] && nanoCoursesResult[i][0].data && nanoCoursesResult[i][0].data.length > 0) {
                            for (let j = 0; j < nanoCoursesResult[i][0].data.length; j++) {
                                if (!itemsMapper[nanoCoursesResult[i][0].data[j].id]) {
                                    itemsMapper[nanoCoursesResult[i][0].data[j].id] = nanoCoursesResult[i][0].data[j];
                                    data[2].push(nanoCoursesResult[i][0].data[j]);
                                }
                            }
                        }
                    }

                    itemsMapper = {};
                    if (itemId) {
                        itemsMapper[itemId] = true;
                    }
                    for (; i < nanoCoursesResult.length; i++) {
                        if (nanoCoursesResult[i].length > 0 && nanoCoursesResult[i][0] && nanoCoursesResult[i][0].data && nanoCoursesResult[i][0].data.length > 0) {
                            for (let j = 0; j < nanoCoursesResult[i][0].data.length; j++) {
                                if (!itemsMapper[nanoCoursesResult[i][0].data[j].id]) {
                                    itemsMapper[nanoCoursesResult[i][0].data[j].id] = nanoCoursesResult[i][0].data[j];
                                    data[3].push(nanoCoursesResult[i][0].data[j]);
                                }
                            }
                        }
                    }

                    data = data.filter(items => items.length > 0);

                    let maxSize = 0;
                    let indexMap = [];
                    let items = [];
                    itemsMapper = {};
                    if (itemId) {
                        itemsMapper[itemId] = true;
                    }

                    const nanoCourses = data.map(subActivityTypes => {
                        subActivityTypes.sort((a, b) => (a.lastCatalogPublishedDate < b.lastCatalogPublishedDate ? -1 : a.lastCatalogPublishedDate > b.lastCatalogPublishedDate ? 1 : 0));
                        indexMap.push(0);
                        if (subActivityTypes && subActivityTypes.length > 0) {
                            if (maxSize < subActivityTypes.length) {
                                maxSize = subActivityTypes.length
                            }
                            return subActivityTypes;
                        }
                        return [];
                    });

                    const canAdd = () => {
                        if (items.length >= 10) {
                            return false;
                        }

                        let can = false;
                        for (let i = 0; i < nanoCourses.length && !can; i++) {
                            can = can || indexMap[i] < nanoCourses[i].length;
                        }

                        return can;
                    };

                    while (canAdd()) {
                        for (let i = 0; i < nanoCourses.length && items.length < 10; i++) {
                            while (indexMap[i] < nanoCourses[i].length && itemsMapper[nanoCourses[i][indexMap[i]].id]) {
                                indexMap[i]++;
                            }
                            if (indexMap[i] < nanoCourses[i].length) {
                                items.push(nanoCourses[i][indexMap[i]]);
                                itemsMapper[nanoCourses[i][indexMap[i]].id] = nanoCourses[i][indexMap[i]];
                            }
                        }
                    }

                    setItems(items);
                }
            };

            if (searchParams.length > 0) {
                search();
            }
            else {
                setItems([]);
            }
        }
        else {
            setItems([]);
        }

        return () => {
            isActive = false;
        };
    }, [dispatchSearch, dimensions, isLogin, lastSchool, skills, subskills, generalSkills, generalSubskills, itemAgeGrades, itemDisciplines, pedagogicValues, itemId]);

    return items;
};

const NanoCourseRecommendations = ({ items = [], title = '' }) => {
    return items && items.length > 0 ?
        <SwimlaneList
            maxNumberOfItems={items.length}
            displayTotalCount={false}
            titleClassName={styles.nanoCourseRecommendations_swimlaneListTitle}
            className={styles.nanoCourseRecommendations_swimlaneList}
            swimlanes={[{
                title: title,
                totalCount: items.length,
                data: items
            }]}
        /> :
        <></>;
};

NanoCourseRecommendations.propTypes = {
    title: PropTypes.string,
    items: PropTypes.any
};

export const NanoCourseRecommendationsPopup = ({ items = [], thumbnailId = null, title = '', isMobile = false, onClose = function () { }, }) => {
    const { getGenderFormatMessage } = useGenderFormatMessage();


    const handleClose = (event) => {
        event.stopPropagation();
        onClose();
    };

    return <div className={`${styles.nanoCourseRecommendations_popup} ${isMobile ? styles.mobileContainer : ''}`}>
        <div className={styles.content}>
            <div className={styles.nanoCourseRecommendations_popup_title}>
                {safelyGetInnerHTML(title)}
            </div>
            <NanoCourseRecommendations items={items} />
        </div>
        <div className={styles.nanoCourseRecommendations_popup_footer}>
            <div className={styles.nanoCourseRecommendations_popup_center}>
                <Button onClick={handleClose} type="line" size="medium" className={styles.footerButton}>
                    {getGenderFormatMessage('close')}
                </Button>
            </div>
        </div>
    </div>;
}

export default NanoCourseRecommendations;