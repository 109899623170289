import React, { useMemo } from 'react';
import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

import styles from './NotificationsPopup.module.scss';

import DropDown from '../DropDown';
import Icon from '../Icon';
import { optionsPropTypes } from '../DataList/PropTypes';

const NotificationsPopup = ({ notifications }) => {
  const {getGenderFormatMessage} = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const iconWidth = useMemo(()=>{
      return isMobileView ? '1.43rem' : 18
    },[isMobileView]
  );

  return (
    <DropDown
      id="header-toolbar_notifications"
      withArrow={false}
      ariaLabel={getGenderFormatMessage('notifications')}
      listClassName={styles.popup}
      buttonClassName={styles.button}
      buttonCategory="upperToolbar"
      buttonLabel="notifications"
      label={
        notifications?.find((nt) => !nt.isRead) ? (
          <Icon className={styles.notificationsIcon} icon="notifications_on" width={iconWidth} />
        ) : (
          <Icon className={styles.notificationsIcon} icon="notifications" width={iconWidth} />
        )
      }
      onSelect={(notification) => {
        console.log(notification);
      }}
    // options={
    //   notifications?.length > 0 &&
    //   notifications.map((notification) => ({ value: notification.id, text: '', ...notification }))
    // }
    >
      {notifications?.length ? (
        (option) => <div>{option.text}</div>
      ) : (
        // <ul>
          <ul className={styles.noNotificationsMessage}>
            <li>{getGenderFormatMessage('no_notifications')}</li>
          </ul>
        // </ul>
      )}
    </DropDown>
  );
};

NotificationsPopup.propTypes = {
  notifications: optionsPropTypes
};

export default NotificationsPopup;
