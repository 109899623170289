import React from 'react';
import PropTypes from 'prop-types';

import { useGenderFormatMessage } from '../../../hooks';
import styles from '../Item.module.scss';

const ItemTypes = ({ itemTypes, isMobileSupported }) => {
  const { getGenderFormatMessage } = useGenderFormatMessage();

  const itemTypeComp = (title, icon) => {
    return <div className={styles.itemTypeTip}>{title}</div>
  };

  return (
    <div className={styles.itemTypes}>
      {itemTypes?.map((itemType, index) => <div className={styles.itemTypeTipWrapper} key={index}>{itemTypeComp(itemType.title, itemType.icon)}</div>)}
      {isMobileSupported && <div className={styles.itemTypeTipWrapper}>{itemTypeComp(getGenderFormatMessage('mobile_compatible'), 'mobile_compatible')}</div>}
    </div>
  );
};

ItemTypes.propTypes = {
  isMobileSupported: PropTypes.bool,
  itemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

export default ItemTypes;
