import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getImageUrl } from '../utils/resourceLoader';
import { CacheProvider } from '../utils/cacheProvider';

export const pageKeys = {
  home: 'home',
  recommendations: 'recommendations',
  books: 'books',
  courses: 'courses',
  sequences: 'sequences',
  myStudio: 'my-studio',
  myFavorites: 'my-favorites',
  myContent: 'my-content',
  myProfile: 'my-profile',
  catalog: 'catalog',
  search: 'search',
  learningManagement: 'learning-management',
  onlineMeetings: 'online-meetings',
  microCourses: 'micro-courses',
  tools: 'tools',
  toolsAI: 'tools-ai',
  teachersLearn: 'teachersLearn',
  teachingSkills: 'teaching-skills',
  teachingPractices: 'teaching-practices',
  selTime: 'sel-time',
  aiMain : 'ai-main',
  aiSkills : 'ai-skills',
  aiContent : 'ai-content',
  aiChat : 'ai-chat',
  notFound: 'not-found'
};

export const usePage = (pageKey, titleSuffix = '') => {
  const { locale } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(null);
  const [data, setData] = useState(null);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [keyWords, setKeyWords] = useState('');
  const [image, setImage] = useState('');
  const [content, setContent] = useState(null);

  const { currentItem } = useSelector(({ items }) => ({
    currentItem: items.currentItem
  }));

  useEffect(() => {
    let isActive = true;

    document.getElementById('rootScroll').dataset.page = pageKey;
    setIsLoading(true);

    const updatePageState = (_data, error) => {
      if (isActive) {
        setData(_data);
        setErrorLoading(error);
        setIsLoading(false);
      }
    };

    const params = { pageKey: pageKey, language: locale };
    const localCacheKey = CacheProvider.generateKey('getPage', params);

    if (CacheProvider.get(localCacheKey)) {
      updatePageState(CacheProvider.get(localCacheKey), null);
    } else {
      window.cet.microservices.nairobiapi.pages
        .getPage(params)
        .then(_data => {
          CacheProvider.set(localCacheKey, _data);
          return updatePageState(_data, null);
        })
        .catch(error => updatePageState(null, error));
    }

    return () => {
      isActive = false;
    };
  }, [locale, pageKey]);

  useEffect(() => {
    let _title = '';
    let _description = '';
    let _keyWords = '';
    let _image = '';
    if (data && !errorLoading) {
      _title = data.title;
      _description = data.description;
      _keyWords = data.keyWords;
      _image = data.image;
    }

    _image = _image || `${window.location.origin}/icon/256x256.png`;

    let canonical = window.location.toString();
    if (currentItem && currentItem.id) {
      canonical = `${window.location.origin}/${locale}/item/${currentItem.id}`;
      _title = currentItem.title || _title;
      _description = currentItem.summary || _description;
      _image = `https:${getImageUrl(currentItem.thumbnailId, 666, 348)}` || _image;
    }
    let canonicalTag = window.document.head.querySelector('link[rel="canonical"]');
    if (canonicalTag) {
      canonicalTag.href = canonical;
    } else {
      canonicalTag = document.createElement('link');
      canonicalTag.rel = 'canonical';
      canonicalTag.href = canonical;
      document.head.append(canonicalTag);
    }

    window.document.title = `${_title} ${titleSuffix}`.trimEnd();
    [
      { selector: ['name', 'description'], content: _description },
      { selector: ['name', 'keywords'], content: _keyWords },
      { selector: ['name', 'twitter:card'], content: 'summary' },
      { selector: ['name', 'twitter:title'], content: _title },
      { selector: ['name', 'twitter:description'], content: _description },
      { selector: ['name', 'twitter:image'], content: _image },
      { selector: ['property', 'og:title'], content: _title },
      { selector: ['property', 'og:description'], content: _description },
      { selector: ['property', 'og:image'], content: _image },
      { selector: ['property', 'og:url'], content: canonical }
    ].forEach(tagData => {
      let metaTag = window.document.head.querySelector(`meta[${tagData.selector[0]}="${tagData.selector[1]}"]`);
      if (metaTag) {
        metaTag.content = tagData.content;
      } else {
        metaTag = document.createElement('meta');
        metaTag.setAttribute(tagData.selector[0], tagData.selector[1]);
        metaTag.content = tagData.content;
        document.head.append(metaTag);
      }
    });

    setTitle(_title);
    setDescription(_description);
    setKeyWords(_keyWords);
    setImage(_image);
  }, [currentItem, data, errorLoading, locale, titleSuffix]);

  useEffect(() => {
    let _content = null;
    if (data && !errorLoading) {
      try {
        _content = JSON.parse(data.content);
        _content.lang = data.lang || 'he';
      } catch (e) {}
    }
    setContent(_content);
  }, [data, errorLoading]);

  return {
    isLoadingPage: isLoading,
    errorLoadingPage: errorLoading,
    pageTitle: title,
    pageDescription: description,
    pageKeyWords: keyWords,
    pageImage: image,
    pageContent: content
  };
};
