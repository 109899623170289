import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.scss';
import sprites from './symbols.svg';

const Icon = ({ icon, title, className, width = 32, height = 32, stroke, strokeWidth, fill }) => {
  const iconClass = [styles.icon, className].join(' ');
  const [isHover, setIsHover] = useState(false);
  const ref = useRef();

  return (
    <>
      <svg
        ref={ref}
        className={iconClass}
        style={{ width, height, stroke, strokeWidth, fill }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {title && !isHover && <title>{title}</title>}
        <use xlinkHref={`${sprites}#${icon}`} />
      </svg>
    </>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stroke: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

export default Icon;
