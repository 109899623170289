import { useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Preview from '../../components/Preview';
import { setPathnameParam, capitalizeFirstLetter, getObjectId } from '../../utils/urls';

import styles from './PreviewContainer.module.scss';
import { useCetEvents } from 'cet-components-lib/dist/hooks';
import { useItem } from '../../hooks';
import { setCurrentItem } from '../../state/items';
import { CacheProvider } from '../../utils/cacheProvider';

export default function PreviewContainer({ itemIdValue, closeHandler, hideButtons }) {
  const dispatch = useDispatch();
  const history = useHistory();
  let { itemId } = useParams();
  itemId = itemId || itemIdValue;
  const { sendLearningEvent } = useCetEvents();
  const { item } = useItem(itemId);

  useEffect(() => {
    if (item) {
      const isWordpressType = item.type === 'Lo' && /^[0-9]+$/.test(item.externalContextId);
      let wordpressType = '';
      if (isWordpressType) {
        const url = new URL(item.contextURL);
        wordpressType = capitalizeFirstLetter(url.hostname.replace('www.', '').split('.')[0]);
      }
      let optionalData = {
        interactionType: isWordpressType ? wordpressType : item.type
      };

      if (item.type === 'Lo' && !isWordpressType) {
        optionalData.contentAgeGroup = item.ageGrades;
        optionalData.contentSubject = item.disciplines;
      }

      if (item.type === 'Course') {
        optionalData.contentParentId = item.externalContextId;
      }

      let key = item.type === 'Book' || item.type === 'Course' || isWordpressType ? 'contentGroupingId' : 'loId';
      optionalData[key] = item.type === 'Course' ? item.additionalInfo.productId : item.externalContextId;

      window.currentHyperlink = window.location.href;
      if (!window.contextReferrer) {
        window.contextReferrer = window.document.referrer || window.location.href;
        let isReload = window.performance && window.performance.getEntriesByType && window.performance.getEntriesByType('navigation');
        isReload = isReload && isReload.length > 0 && isReload.type && isReload.type === 'reload';
        if (isReload) {
          sendLearningEvent({
            verbType: 'exited',
            objectId: getObjectId(),
            objectName: item.title,
            optionalData
          });
        }
      }

      sendLearningEvent({
        verbType: 'launched',
        objectId: getObjectId(),
        objectName: item.title,
        optionalData
      });

      return () => {
        sendLearningEvent({
          verbType: 'exited',
          objectId: getObjectId(),
          objectName: item.title,
          optionalData
        });
        window.contextReferrer = window.currentHyperlink || window.location.href;
      };
    }
  }, [item, sendLearningEvent]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentItem(null));
    };
  }, [dispatch]);

  const onClose = useCallback(() => {
    if (typeof closeHandler === 'function') {
      closeHandler();
      return;
    }
    let historyState = setPathnameParam('item', null);
    if (historyState?.search) {
      historyState.search = historyState.search.replaceAll('?fav', '').replaceAll('&fav', '');
    }
    CacheProvider.set('previewContainerItems', JSON.stringify([]));
    history.push(`${historyState.pathname}${historyState.search}`, historyState);
  }, [closeHandler, history])

  const containerItems = useMemo(() => CacheProvider.get('previewContainerItems'), [itemId]);

  return (
    itemId && (
      <>
        <div className={styles.preview}>
          <Preview
            itemId={itemId}
            containerItems={containerItems}
            externalContextId={item?.externalContextId}
            itemUrl={item?.contextURL}
            itemType={item?.type}
            onClose={onClose}
            hideButtons={hideButtons}
          />
        </div>
      </>
    )
  );
}
