import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Preloader } from 'cet-components-lib/dist/UI/Preloader';
import { useCetEvents, useDeviceData } from 'cet-components-lib/dist/hooks';
import { useVisibilityCheck, useGenderFormatMessage } from '../../hooks';
import { getImageUrl } from '../../utils/resourceLoader';
import { setPathnameParam, thumbnailsStorage, capitalizeFirstLetter } from '../../utils/urls';
import { CacheProvider } from '../../utils/cacheProvider';

import Book from './Book';
import Course from './Course';
import Nano from './Nano';
import Lo from './Lo';
import EmptyItem from './EmptyItem';

import styles from './Item.module.scss';

const Item = ({ data, index, containerItemsIds, parentTitle, containerType = 'swimlane' }) => {
  const history = useHistory();
  const { isMobileView } = useDeviceData();
  const { sendLearningEvent } = useCetEvents();

  const [isReady, setIsReady] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [itemType, setItemType] = useState();
  const [itemTypes, setItemTypes] = useState([]);

  const ref = useRef();
  const isMountedRef = useRef();

  const { isVisible } = useVisibilityCheck(ref);
  const { getGenderFormatMessage } = useGenderFormatMessage();

  const { favorites } = useSelector(({ user }) => ({
    favorites: user.favorites
  }));

  const checkIsInFavorites = useCallback(() => {
    setIsInFavorites(false);
    if (data) {
      for (let i = 0; i < favorites.length; i++) {
        for (let j = 0; j < favorites[i].favorites.length; j++) {
          if (favorites[i].favorites[j].contentCatalogId === data.id) {
            setIsInFavorites(true);
          }
        }
      }
    }
  }, [data, favorites]);

  const handleItemIsReady = () => setIsReady(true);

  const handleGoToItemClick = () => {
    if (data.contextURL) {
      const isWordpressType = itemType === 'Lo' && /^[0-9]+$/.test(data.externalContextId);
      let wordpressType = '';
      if (isWordpressType) {
        const url = new URL(data.contextURL);
        wordpressType = capitalizeFirstLetter(url.hostname.replace('www.', '').split('.')[0]);
      }
      let optionalData = {
        interactionType: isWordpressType ? wordpressType : itemType
      };
      if (itemType === 'Lo' && !isWordpressType) {
        optionalData.contentAgeGroup = data.ageGrades
          ? Object.keys(data.ageGrades)
            .map(grade => grade.split('grade')[1])
            .join(', ')
          : '';
        optionalData.contentSubject = data.disciplines ? Object.keys(data.disciplines).join(', ') : '';
      }
      let key = itemType === 'Book' || itemType === 'Course' || itemType === 'Nano' || isWordpressType ? 'contentGroupingId' : 'loId';
      optionalData[key] = data.externalContextId;
      sendLearningEvent({
        verbType: 'launched',
        objectId: data.contextURL,
        objectName: data.title,
        optionalData
      });
    }
  };

  const handleTellMeMoreClick = id => {
    CacheProvider.set('previewContainerItems', JSON.stringify(containerItemsIds ? containerItemsIds : []));
    let historyState = setPathnameParam('item', id);
    history.push(`${historyState.pathname}${historyState.search}`, historyState);
  };

  const handleUpdateFavorites = id => {
    CacheProvider.set('previewContainerItems', JSON.stringify([]));
    let historyState = setPathnameParam('item', id);

    if (historyState.search) {
      historyState.search += '&fav';
    } else {
      historyState.search = '?fav';
    }

    history.push(`${historyState.pathname}${historyState.search}`, historyState);
  };

  const handleOnBannerError = event => {
    if (event.target.src.toLowerCase().indexOf(thumbnailsStorage) >= 0) {
      event.target.src = getImageUrl(data.thumbnailId, 220, 186, false);
    } else {
      event.target.src = '/bad-image.svg'; //'/bad-image.svg' '/bad_images/ex_parit.png' '/bad_images/ex_book.png'
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = true);
  });

  useEffect(() => {
    if (data) {
      setItemTypes(
        data.itemTypes &&
        Object.keys(data.itemTypes).map(key => ({
          icon: key,
          title: data.itemTypes[key]
        }))
      );

      data.subTitle = data.subTitle
        ? data.subTitle
        : Object.keys(data.ageGrades ? data.ageGrades : [])
          .map(grade => getGenderFormatMessage(grade))
          .join(', ');

      const now = new Date();
      const publishedDate = new Date(data.lastCatalogPublishedDate);
      const dateDiff = (now.getFullYear() - publishedDate.getFullYear()) * 12 - publishedDate.getMonth() + now.getMonth();
      setIsNew(dateDiff < 2);
    }

    const itemContext = data && data.itemContext ? data.itemContext.toLowerCase() : '';
    const currentType =
      data && data.type ? data.type : itemContext === 'book' ? 'Book' : itemContext === 'course' ? 'Course' : itemContext === 'teacherref-nano-course' ? 'Nano' : 'Lo';
    setItemType(currentType);
  }, [data, getGenderFormatMessage]);

  useEffect(() => {
    if (isMobileView || isVisible) {
      checkIsInFavorites();
    }
  }, [checkIsInFavorites, data, isVisible, favorites, isMobileView]);

  if (!data) {
    return null;
  }

  const isCourse = !!(itemType === 'Course' && data && data.itemTypes && data.itemTypes.course);

  return (
    <>
      <div className={`${styles.container} ${styles['containerType_' + containerType]} ${itemType === 'EmptyItem' ? styles.container_emptyItem : ''}`} ref={ref}>
        {isVisible && !isReady && itemType !== 'EmptyItem' && (
          <div
            style={{
              backgroundColor: 'rgba(255,255,255, 1)',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <Preloader url="/json/item-loader.json" />
          </div>
        )}

        <div style={{ width: data.width }}>
          {itemType === 'Course' ? (
            <Course
              {...data}
              isNew={isNew}
              index={index}
              parentTitle={parentTitle}
              containerType={containerType}
              itemTypes={itemTypes}
              isCourse={isCourse}
              onItemReady={handleItemIsReady}
              onItemUrlOpen={handleGoToItemClick}
              onTellMeMore={handleTellMeMoreClick}
              onUpdateFavorites={handleUpdateFavorites}
              isActive={isVisible}
              isInFavorites={isInFavorites}
              banner={getImageUrl(data.thumbnailId, 220, 186) || '/bad-image.svg'}
              onBannerError={handleOnBannerError}
            />
          ) : itemType === 'Book' ? (
            <Book
              {...data}
              isNew={isNew}
              index={index}
              parentTitle={parentTitle}
              containerType={containerType}
              itemTypes={itemTypes}
              isCourse={isCourse}
              onItemReady={handleItemIsReady}
              onItemUrlOpen={handleGoToItemClick}
              onTellMeMore={handleTellMeMoreClick}
              onUpdateFavorites={handleUpdateFavorites}
              isActive={isVisible}
              isInFavorites={isInFavorites}
              banner={getImageUrl(data.thumbnailId, 220, 186) || '/bad-image.svg'}
              onBannerError={handleOnBannerError}
            />
          ) : itemType === 'Nano' ? (
            <Nano
              {...data}
              isNew={isNew}
              index={index}
              parentTitle={parentTitle}
              containerType={containerType}
              itemTypes={itemTypes}
              isCourse={isCourse}
              onItemReady={handleItemIsReady}
              onItemUrlOpen={handleGoToItemClick}
              onTellMeMore={handleTellMeMoreClick}
              onUpdateFavorites={handleUpdateFavorites}
              isActive={isVisible}
              isInFavorites={isInFavorites}
              banner={getImageUrl(data.thumbnailId, 220, 186) || '/bad-image.svg'}
              onBannerError={handleOnBannerError}
            />
          ) : itemType === 'EmptyItem' ? (
            <EmptyItem {...data} containerType={containerType} />
          ) : (
            <Lo
              {...data}
              isNew={isNew}
              index={index}
              parentTitle={parentTitle}
              containerType={containerType}
              itemTypes={itemTypes}
              isCourse={isCourse}
              onItemReady={handleItemIsReady}
              onItemUrlOpen={handleGoToItemClick}
              onTellMeMore={handleTellMeMoreClick}
              onUpdateFavorites={handleUpdateFavorites}
              isActive={isVisible}
              isInFavorites={isInFavorites}
              banner={getImageUrl(data.thumbnailId, 220, 186) || '/bad-image.svg'}
              onBannerError={handleOnBannerError}
            />
          )}
        </div>
      </div>
    </>
  );
};

Item.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number,
  parentTitle: PropTypes.string,
  containerType: PropTypes.oneOf(['swimlane', 'grid'])
};

export default Item;
