import { useEffect, useState } from 'react';
import styles from './Item.module.scss';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

export const useClassName = (isReady = false, isActive = false) => {
  const [containerClassName, setContainerClassName] = useState();
  const [infoClassName, setInfoClassName] = useState();
  const {isMobileView} = useDeviceData();

  useEffect(() => {
    setContainerClassName(
      `${styles.container} ${isReady ? (isActive && !isMobileView ? styles.zooming : ' ') : styles.hidden}`.trimEnd()
    );
    setInfoClassName(`${styles.info}`.trimEnd());
  }, [isActive, isMobileView, isReady]);

  return [containerClassName, infoClassName];
};
