import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextImageBanner from '../TextImageBanner';
import { CacheProvider } from '../../utils/cacheProvider';

import styles from './RandomTextImageBanner.module.scss';

const RandomTextImageBanner = ({ pageKey, className = '' }) => {
  const { personalBanners } = useSelector(({ user }) => ({
    personalBanners: user && user.personalBanners ? user.personalBanners : []
  }));
  const [banner, setBanner] = useState(null);

  useEffect(() => {
    let banner = null;

    if (pageKey && personalBanners && personalBanners.length > 0) {
      let bannerMapper = CacheProvider.get('randomTextImageBannerMapper');
      try {
        bannerMapper = bannerMapper ? JSON.parse(bannerMapper) : {};
      } catch (error) {
        bannerMapper = {};
      }

      if (!bannerMapper.pages) {
        bannerMapper.pages = {};
      }
      if (!bannerMapper.ids) {
        bannerMapper.ids = {};
      }

      if (bannerMapper.pages[pageKey]) {
        banner = bannerMapper.pages[pageKey];
      }

      if (!banner) {
        let filterBanners1 = [],
          filterBanners2 = [];

        personalBanners?.forEach(banner => {
          let addToFilter = false;
          if (banner?.pages?.length === 0 || banner?.pages?.indexOf(pageKey) >= 0) {
            addToFilter = true;
          }

          if (addToFilter) {
            filterBanners1.push(banner);
            if (!bannerMapper.ids[banner?.id]) {
              filterBanners2.push(banner);
            }
          }
        });

        let filterBanners = filterBanners2.length > 0 ? filterBanners2 : filterBanners1.length > 0 ? filterBanners1 : [];
        if (filterBanners.length > 0) {
          let getRandomInt = (min, max) => {
            return window.Math.floor(window.Math.random() * (max - min + 1) + min);
          };
          let index = getRandomInt(0, filterBanners.length - 1);
          banner = filterBanners[index];
        }

        if (banner) {
          bannerMapper.ids[banner.id] = true;
          bannerMapper.pages[pageKey] = banner;
        }
        CacheProvider.set('randomTextImageBannerMapper', JSON.stringify(bannerMapper));
      }
    }

    setBanner(banner);
  }, [personalBanners, pageKey]);

  return (
    !!banner && (
      <div
        className={`${styles.randomTextImageBanner} ${className}`}
        style={banner.content.has_background_color && banner.content.background_color ? { color: banner.content.background_color } : {}}
      >
        <div className={styles.randomTextImageBannerContaner}>
          <TextImageBanner
            {...banner.content}
            {...(banner.content.button
              ? {
                  has_button: true,
                  button_text: banner.content.button.text,
                  button_link: banner.content.button.link,
                  button_link_target: banner.content.button.link_target || '_blank'
                }
              : { has_button: false })}
            image_side="end"
            skinType="random"
          />
        </div>
      </div>
    )
  );
};

RandomTextImageBanner.propTypes = {};

export default RandomTextImageBanner;
