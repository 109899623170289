import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { UiContext } from '../../contexts';
import { useBooks, useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { useClassName } from './useClassName';
import { removeBookFromMyBooks } from '../../state/user';

import Href from '../Href';
import Button from '../Button';
import FavoritesButton from '../FavoritesButton';
import Tooltip from '../Tooltip';
import { NewTag, AttachedTag } from '../Tag';
import ItemTypes from './Partials/ItemTypes';
import YearCycles from './Partials/YearCycles';

import styles from './Item.module.scss';
import Icon from '../Icon';

const Item = (props) => {
  const {
    index,
    parentTitle,
    id,
    externalContextId,
    banner,
    title,
    subTitle,
    duration,
    professions,
    itemTypes,
    yearCycles,
    isNew,
    isActive,
    isInFavorites,
    isMobileSupported,
    onTellMeMore,
    onUpdateFavorites,
    onItemReady,
    onBannerError,
    containerType,
  } = props;

  const myBooksConfirmRef = useRef();
  const intl = useIntl();
  const {getGenderFormatMessage} = useGenderFormatMessage();
  const {isMobileView} = useDeviceData();
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [containerClassName, infoClassName] = useClassName(isReady, isActive);
  const [isInMyBooks, setIsInMyBooks] = useState(false);
  const [activityInfo, setActivityInfo] = useState(null);
  const [lastLocation, setLastLocation] = useState();
  const [, updateUiData] = useContext(UiContext);
  const { teacherId, books, isAttached } = useSelector(({ user }) => ({
    teacherId: user.info?.userId,
    books: user.books,
    isAttached: Boolean(user.activities.find((activity) =>
        externalContextId
        && activity.externalId?.toLocaleLowerCase() === externalContextId.toLocaleLowerCase()
        && activity.schoolProfileId?.toLocaleLowerCase() === user.lastSchool?.externalSchoolId?.toLocaleLowerCase()
      )),
  }));
  const { removeBookFromTeacherBooks } = useBooks();
  const optionalData = {objectAdditionalInformation: {itemTitle: title, itemIndex: index, parentTitle}, contentGroupingId: (externalContextId || '').toLocaleUpperCase()}

  const handleTellMeMoreClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onTellMeMore(id);
      return false;
    },
    [onTellMeMore, id]
  );

  const handleOnRemoveFromMyBooks = (closeDropBox) => {
    dispatch(removeBookFromMyBooks(externalContextId));

    let uiData = {
      snackbarMessage: `${getGenderFormatMessage('the_book')} ${title} ${getGenderFormatMessage('removed_successfully')}`,
    };
    if (closeDropBox) {
      uiData.dropBoxContent = null;
    }

    updateUiData(uiData);
  };

  const handleOnUpdateFavorites = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onUpdateFavorites(id);
    },
    [onUpdateFavorites, id]
  );

  const handleLastLocationClick = (event) => {
    event.stopPropagation();
  };

  const handleToggleRemoveFromMyBooks = (event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (isMobileView) {
      updateUiData({
        dropBoxContent: myBooksConfirmRef,
        dropBoxType: false,
        dropBoxCloseType: 'bottom'
      });
    }
  };

  const handleItemIsReady = () => {
    setIsReady(true);
    onItemReady();
  };

  useEffect(() => {
    if (books && books[0]?.data.length > 0) {
      const book = books[0].data.find((book) => book.externalContextId === externalContextId);
      if (book) {
        setIsInMyBooks(true);
        setActivityInfo(book.activityInfo);
      } else {
        setIsInMyBooks(false);
        setActivityInfo(null);
      }
    }
  }, [books, externalContextId]);

  useEffect(() => {
    if (activityInfo) {
     setLastLocation(`${activityInfo.currentChapter} - ${getGenderFormatMessage('page')} ${activityInfo.currentPage}`);
    }
  }, [activityInfo, getGenderFormatMessage, intl]);


  myBooksConfirmRef.current = <div className={styles.selectOption}>
    <Button
      type="none"
      className={styles.tooltip}
      onClick={() => {
        const isRemoved = removeBookFromTeacherBooks(teacherId, externalContextId);
        if (isRemoved) {
          handleOnRemoveFromMyBooks(true);
        }
      }}
      category="bookTile"
      label="removeBook"
      optionalData={optionalData}
    >
      {getGenderFormatMessage('remove_from_my_books')}
    </Button>
  </div>;

  const isRTL = intl && (intl.locale === 'he' || intl.locale === 'ar');

  const durationComp = (duration !== null && duration > 0) && <div className={styles.duration}>
    <span style={isRTL ? { marginLeft: '1rem' } : { marginRight: '1rem' }}>
      {<>{duration > 120 ? '120+' : duration} {getGenderFormatMessage('minutes')}</>}
    </span>
  </div>;

  const isMobileGrid = isMobileView && containerType === 'grid';

  const lastLocationComp = isInMyBooks && lastLocation && (isMobileView ? 
    <Button 
      className={styles.extraInfoBtn}
      href={activityInfo.currentPageUrl}
      target="_blank"
      rel="noreferrer"
      onClick={handleLastLocationClick}
      category="bookTile"
      label="lastPageLink"
      optionalData={optionalData}
      title={lastLocation}
      type="primary"
    >
      {getGenderFormatMessage('preview_book_open_last_page')}
      {isMobileGrid && <Icon icon="arrow"/>}
    </Button> : 
    <Tooltip
      offset={0}
      isInteractive={true}
      position="top"
      content={<div className={styles.selectOption}>
        <Button
          type="none"
          size="small"
          className={styles.tooltip}
          href={activityInfo.currentPageUrl}
          target="_blank"
          rel="noreferrer"
          onClick={handleLastLocationClick}
          category="bookTile"
          label="lastPageLink"
          optionalData={optionalData}
          styles={{whiteSpace: 'unset'}}
        >{lastLocation}</Button>
      </div>}
    >
      <Button 
        className={styles.extraInfoBtn}
        href={activityInfo.currentPageUrl}
        target="_blank"
        rel="noreferrer"
        onClick={handleLastLocationClick}
        category="bookTile"
        label="lastPageLink"
        optionalData={optionalData}
        title={lastLocation}
        type="primary"
      >{getGenderFormatMessage('preview_book_open_last_page')}</Button>
    </Tooltip>
  );

  const itemTypesComp = <ItemTypes itemTypes={itemTypes} isMobileSupported={isMobileSupported}/>;
  const myBooksToggleComp = isInMyBooks && (
    <div className={styles.myBooksToggle} >
      {isMobileView ? 
        <button
          className={styles.myBooksToggleButton}
          aria-label={getGenderFormatMessage('remove_from_my_books')}
          onClick={handleToggleRemoveFromMyBooks}
        >
          <span />
          <span />
          <span />
        </button> :
        <Tooltip
          offset={0}
          isInteractive={true}
          position="bottom"
          content={<div className={styles.selectOption}>
            <Button
              type="none"
              size="small"
              className={styles.tooltip}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                const isRemoved = removeBookFromTeacherBooks(teacherId, externalContextId);
                if (isRemoved) {
                  handleOnRemoveFromMyBooks();
                }
              }}
              category="bookTile"
              label="removeBook"
              optionalData={optionalData}
            >
              {getGenderFormatMessage('remove_from_my_books')}
            </Button>
          </div>}
        >
          <button
            className={styles.myBooksToggleButton}
            aria-label={getGenderFormatMessage('remove_from_my_books')}
            onClick={handleToggleRemoveFromMyBooks}
          >
            <span />
            <span />
            <span />
          </button>
        </Tooltip>
      }
    </div>
  );

  if (isMobileGrid) {
    return (
      <article className={containerClassName}>
        <div className={styles.tags}>
          {isNew && !isInMyBooks && <NewTag label={getGenderFormatMessage('new')} withSpout={false} className={styles.mobileGridTag} />}
          {isAttached && <AttachedTag label={getGenderFormatMessage('attached')} withSpout={false} className={styles.mobileGridTag} />}
        </div>
        <Href className={`${styles.tellMeMoreHandler}`} onClick={handleTellMeMoreClick} category="loTile" label="moreInfo" optionalData={optionalData}>
          <div className={styles.bannerContainer}>
            <img
              onLoad={handleItemIsReady}
              onError={(event) => {
                onBannerError(event);
                handleItemIsReady();
              }}
              src={banner}
              className={styles.banner}
              alt={title}
              width={100}
              height={85}
            />
          </div>
          <div className={styles.content}>
            <h3 className={styles.title}>{title?.trim()}</h3>
            <YearCycles yearCycles={yearCycles} />
            {itemTypesComp}
            <div className={infoClassName}>
              <div className={styles.subTitle}>{subTitle}</div>
              {durationComp}
              <div className={styles.professions}>{professions}</div>
            </div>
            {myBooksToggleComp}
          </div>
          <div className={styles.extraInfo}>
            <div className={styles.extraInfo_content}>
              {lastLocationComp}
            </div>
          </div>
        </Href>
      </article>
    );
  }
  
  return (
    <article className={containerClassName}>
      <div className={styles.tags}>
        {isNew && !isInMyBooks && <NewTag label={getGenderFormatMessage('new')} />}
        {isAttached && <AttachedTag label={getGenderFormatMessage('attached')} />}
      </div>
      <Href className={`${styles.tellMeMoreHandler}`} onClick={handleTellMeMoreClick} category="loTile" label="moreInfo" optionalData={optionalData}>
        <div className={styles.bannerContainer}>
          <img
            onLoad={handleItemIsReady}
            onError={(event) => {
              onBannerError(event);
              handleItemIsReady();
            }}
            src={banner}
            className={styles.banner}
            alt={title}
            width={220}
            height={186}
          />
          <YearCycles yearCycles={yearCycles} />
          <div className={styles.bannerContent}>
            <div className={styles.button}>
              <FavoritesButton
                onClick={handleOnUpdateFavorites}
                className={styles.favorites + ' item_favorites-button'}
                removeFromFavoritesLabel={getGenderFormatMessage('remove_from_favorites')}
                addToFavoritesLabel={getGenderFormatMessage('add_to_favorites')}
                isInFavorites={isInFavorites}
                category="bookTile"
                label="favorite"
                optionalData={optionalData}
                ariaLabel={isInFavorites ? getGenderFormatMessage('remove_from_favorites') : getGenderFormatMessage('add_to_favorites')}
              />
            </div>
            {itemTypesComp}
          </div>
          {myBooksToggleComp}
        </div>
        <div className={styles.content}>
          <h3 className={styles.title}>{title?.trim()}</h3>
          <div className={infoClassName}>
            <div className={styles.subTitle}>{subTitle}</div>
              {durationComp}
            <div className={styles.professions}>{professions}</div>
          </div>
          <div className={styles.extraInfo}>
            <div className={styles.extraInfo_content}>
              {lastLocationComp}
            </div>
          </div>
        </div>
      </Href>
    </article>
  );
};

Item.propTypes = {
  index: PropTypes.number,
  parentTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  externalContextId: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  professions: PropTypes.string,
  contextURL: PropTypes.string.isRequired,
  itemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  yearCycles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      orderBy: PropTypes.number,
      parentId: PropTypes.string,
    })
  ),
  isNew: PropTypes.bool,
  isActive: PropTypes.bool,
  isInFavorites: PropTypes.bool,
  isMobileSupported: PropTypes.bool,
  isInMyBooks: PropTypes.bool,
  activityInfo: PropTypes.object,
  onTellMeMore: PropTypes.func.isRequired,
  onUpdateFavorites: PropTypes.func.isRequired,
  onRemoveFromMyBooks: PropTypes.func,
  onItemUrlOpen: PropTypes.func,
  onItemReady: PropTypes.func.isRequired,
  onBannerError: PropTypes.func,
  containerType: PropTypes.oneOf(['swimlane', 'grid']),
};
Item.displayName = 'Book';
export default Item;
