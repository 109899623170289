import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';
import { UiContext } from '../../contexts';
import styles from './Item.module.scss';
import { useClassName } from './useClassName';

import Button from '../Button';
import Href from '../Href';
import Tooltip from '../Tooltip';
import DropDown from '../DropDown';
import { NewTag } from '../Tag';
import FavoritesButton from '../FavoritesButton';
import ItemTypes from './Partials/ItemTypes';
import YearCycles from './Partials/YearCycles';

//const activities = {additionalInfo: {audiences: [{id: '111111', name: 'n111111'}, {id: '111111', name: 'n111111'}], rootFolder: "ttttttttttttt"}};

const Item = ({
  index,
  parentTitle,
  id,
  externalContextId,
  banner,
  title,
  subTitle,
  duration,
  chapters,
  contextURL,
  itemTypes,
  isCourse,
  yearCycles,
  isNew,
  isActive,
  isInFavorites,
  isMobileSupported,
  onTellMeMore,
  onUpdateFavorites,
  onItemUrlOpen,
  onItemReady,
  onBannerError,
  containerType,
}) => {
  const intl = useIntl();
  const {getGenderFormatMessage} = useGenderFormatMessage();
  const [{ }, updateUiData] = useContext(UiContext);
  const { isMobileView } = useDeviceData();
  const [isReady, setIsReady] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [containerClassName, infoClassName] = useClassName(isReady, isActive);
  const containerRef = useRef();
  const { activities } = useSelector(({ user }) => {
    let schoolId = user.lastSchool ? user.lastSchool.externalSchoolId : '';
    schoolId = schoolId ? schoolId.toLocaleUpperCase() : '';
    return {
      activities: user.activities.find((activity) => activity.externalId === externalContextId && activity.schoolProfileId && activity.schoolProfileId.toLocaleUpperCase() === schoolId)
    };
  });
  const optionalData = { objectAdditionalInformation: { itemTitle: title, itemIndex: index, parentTitle }, contentGroupingId: (externalContextId || '').toLocaleUpperCase() };

  const handleTellMeMoreClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onTellMeMore(id);
      return false;
    },
    [onTellMeMore, id]
  );

  const handleOnUpdateFavorites = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onUpdateFavorites(id);
    },
    [onUpdateFavorites, id]
  );

  const handleItemIsReady = () => {
    setIsReady(true);
    onItemReady();
  };

  // const handOpenAudience = (event) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  // };

  useEffect(() => {
    if (activities?.additionalInfo?.audiences) {
      setAudiences(activities.additionalInfo.audiences.filter(audience => !audience.archiveYear));
    }
  }, [activities]);

  const isMobileGrid = isMobileView && containerType === 'grid';

  const audiencesTooltipComp = audiences?.length > 0 && contextURL && (isMobileView ?
    <DropDown buttonClassName={styles.audiencesBtn} label={`${getGenderFormatMessage('my_audiences')} (${audiences.length})`} withArrow={isMobileGrid} >
      <div className={styles.audiences}>
        <ul>
          {audiences.sort((a, b) => a.name.localeCompare(b.name)).map((audience) => (
            <li key={audience.id}>
              <Href to={`${contextURL}?audience=${audience.id}`} target="_blank" onClick={() => { updateUiData({ dropBoxContent: null }); }}>{audience.name}</Href>
            </li>
          ))}
        </ul>
      </div>
    </DropDown> :
    <Tooltip
      isActive={true}
      position={'top'}
      isInteractive
      offset={index === 1 ? -20 : 0}
      content={
        <div className={styles.audiences}>
          {audiences.sort((a, b) => a.name.localeCompare(b.name)).map((audience) => (
            <div key={audience.id}>
              <Href to={`${contextURL}?audience=${audience.id}`} target="_blank" onClick={(event) => {event.stopPropagation();}} >{audience.name}</Href>
            </div>
          ))}
        </div>
      }
    >
      <Button aria-label={getGenderFormatMessage('my_audiences')} tabIndex={isActive && isReady ? 0 : -1} type="link" onClick={(event) => { event.stopPropagation(); event.preventDefault(); }} className={styles.audiencesBtn}>
        {getGenderFormatMessage('my_audiences')} ({audiences.length})
      </Button>
    </Tooltip>);

    
  const isRTL = intl && (intl.locale === 'he' || intl.locale === 'ar');

  const durationComp = duration > 0 && <div className={styles.duration}>
    <span style={isRTL ? { marginLeft: '1rem' } : { marginRight: '1rem' }}>
      {isCourse && <>{duration === 999 ? getGenderFormatMessage('yearlyCourse') : `${duration} ${getGenderFormatMessage('hours')}`}</>}
      {!isCourse && <>{duration > 120 ? '120+' : duration} {getGenderFormatMessage('minutes')}</>}
    </span>
  </div>;

  const itemTypesComp = <ItemTypes itemTypes={itemTypes} isMobileSupported={isMobileSupported} />;
  
  if (isMobileGrid) {
    return (
      <article className={containerClassName} ref={containerRef}>
        <div className={styles.tags}>
          {isNew && !audiences?.length && <NewTag label={getGenderFormatMessage('new')} withSpout={false} className={styles.mobileGridTag} />}
        </div>
  
        <Href className={`${styles.tellMeMoreHandler}`} onClick={handleTellMeMoreClick} category="loTile" label="moreInfo" optionalData={optionalData}>
          <div className={styles.bannerContainer}>
            <img
              onLoad={handleItemIsReady}
              onError={(event) => {
                onBannerError(event);
                handleItemIsReady();
              }}
              src={banner}
              className={styles.banner}
              alt={title}
              width={100}
              height={85}
            />
          </div>
          <div className={styles.content}>
            <h3 className={styles.title}>{title?.trim()}</h3>
            <YearCycles yearCycles={yearCycles} />
            {itemTypesComp}
            <div className={infoClassName}>
              <div className={styles.subTitle}>{subTitle}</div>
              {durationComp}
            </div>
          </div>
          <div className={styles.extraInfo}>
            <div className={styles.extraInfo_content}>
              {audiencesTooltipComp}
            </div>
          </div>
        </Href>
      </article>
    );
  }

  return (
    <article className={containerClassName} ref={containerRef}>
      <div className={styles.tags}>
        {isNew && !audiences?.length && <NewTag label={getGenderFormatMessage('new')} />}
      </div>

      <Href className={`${styles.tellMeMoreHandler}`} onClick={handleTellMeMoreClick} category="loTile" label="moreInfo" optionalData={optionalData}>
        <div className={styles.bannerContainer}>
          <img
            onLoad={handleItemIsReady}
            onError={(event) => {
              onBannerError(event);
              handleItemIsReady();
            }}
            src={banner}
            className={styles.banner}
            alt={title}
            width={220}
            height={186}
          />
          <YearCycles yearCycles={yearCycles} />
          <div className={styles.bannerContent}>
            <div className={styles.button}>
              <FavoritesButton
                onClick={handleOnUpdateFavorites}
                className={styles.favorites + ' item_favorites-button'}
                removeFromFavoritesLabel={getGenderFormatMessage('remove_from_favorites')}
                addToFavoritesLabel={getGenderFormatMessage('add_to_favorites')}
                isInFavorites={isInFavorites}
                category="courseTile"
                label="favorite"
                optionalData={optionalData}
                ariaLabel={isInFavorites ? getGenderFormatMessage('remove_from_favorites') : getGenderFormatMessage('add_to_favorites')}
              />
            </div>
            {itemTypesComp}
          </div>
        </div>
        <div className={styles.content}>
          <h3 className={styles.title}>{title?.trim()}</h3>
          <div className={infoClassName}>
            <div className={styles.subTitle}>{subTitle}</div>
            {durationComp}
          </div>
          <div className={styles.extraInfo}>
            <div className={styles.extraInfo_content}>
              {audiencesTooltipComp}
            </div>
          </div>
        </div>
      </Href>
    </article>
  );
};

Item.propTypes = {
  index: PropTypes.number,
  parentTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  externalContextId: PropTypes.string.isRequired,
  name: PropTypes.string,
  rootFolder: PropTypes.string,
  banner: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  duration: PropTypes.number,
  chapters: PropTypes.number,
  contextURL: PropTypes.string.isRequired,
  itemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  isCourse: PropTypes.bool,
  yearCycles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      orderBy: PropTypes.number,
      parentId: PropTypes.string,
    })
  ),
  isNew: PropTypes.bool,
  isActive: PropTypes.bool,
  isInFavorites: PropTypes.bool,
  isMobileSupported: PropTypes.bool,
  isInMyBooks: PropTypes.bool,
  onTellMeMore: PropTypes.func.isRequired,
  onUpdateFavorites: PropTypes.func.isRequired,
  onItemUrlOpen: PropTypes.func,
  onItemReady: PropTypes.func.isRequired,
  onBannerError: PropTypes.func,
  containerType: PropTypes.oneOf(['swimlane', 'grid']),
};
Item.displayName = 'Course';
export default Item;
