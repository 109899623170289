import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ContentHeader from './Partials/ContentHeader';
import SubjectsList from './Partials/SubjectsList';
import ReferencesList from './Partials/ReferencesList';
import TableOfContent from './Partials/BookTableOfContent';
import AuthorizationMessage from './Partials/AuthorizationMessage';
import NanoCourseRecommendations from './Partials/NanoCourseRecommendations';
import Icon from '../Icon';
import Href from '../Href';
import { NewTag } from '../Tag';
import Tabs from '../Tabs';
import StuckMenu from '../StuckMenu';
//import { kotarShopUrl } from '../../utils/urls';
import { useGenderFormatMessage } from '../../hooks';
import { useDeviceData } from 'cet-components-lib/dist/hooks';

import styles from './Preview.module.scss';
import { CacheProvider } from '../../utils/cacheProvider';

const Item = ({
  itemId,
  documentId,
  title,
  type,
  externalContextId,
  contextURL,
  banner,
  professions,
  grades,
  summary,
  yearCycles,
  skills,
  itemDimensions,
  syllabus,
  additionalInfo,
  searchReferences,
  references,
  itemTypes,
  isInFavorites,
  isMobileSupported,
  isNew,
  onOpenClick,
  onShareClick,
  onFavoritesClick,
  onBannerError,
  onBannerLoad,
  canShowNanoCourseRecommendationsAnimation,
  nanoCourseRecommendationsItems,
  hideButtons
}) => {
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { isMobileView } = useDeviceData();
  const [selectedTab, setSelectedTab] = useState('summary');
  const [bookMarketingURL, setBookMarketingURL] = useState('');

  const [showNanoCourseRecommendationsAnimation, setShowNanoCourseRecommendationsAnimation] = useState(false);

  const { myBookData, isGuest } = useSelector(({ user }) => ({
    myBookData:
      user.books && user.books.length > 0 && user.books[0].data && user.books[0].data.length > 0
        ? user.books[0].data.find(book => book.externalContextId && documentId && book.externalContextId.toLocaleLowerCase() === documentId.toLocaleLowerCase())
        : null,
    isGuest: !user.info?.userId
  }));

  useEffect(() => {
    //setBookMarketingURL(`${kotarShopUrl}?nBookID=${externalContextId}`);
  }, [externalContextId]);

  useEffect(() => {
    if (
      canShowNanoCourseRecommendationsAnimation &&
      nanoCourseRecommendationsItems &&
      nanoCourseRecommendationsItems.length > 0 &&
      !CacheProvider.get('PreviewNanoCourseRecommendationsAnimationDone')
    ) {
      setShowNanoCourseRecommendationsAnimation(true);
      CacheProvider.set('PreviewNanoCourseRecommendationsAnimationDone', '1');
    }
  }, [nanoCourseRecommendationsItems, canShowNanoCourseRecommendationsAnimation]);

  const nanoCourseRecommendations = (
    <NanoCourseRecommendations title={getGenderFormatMessage('preview_nanoCourseRecommendations_title')} items={nanoCourseRecommendationsItems} />
  );

  if (isMobileView) {
    return (
      <div className={styles.mobileContainer} data-scrolling-handler-element="scroller">
        <div className={styles.topMarker} data-scrolling-handler-element="itemTopMarker"></div>
        <div className={styles.banner}>
          <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={239} />
          <div className={styles.tags}>{isNew && <NewTag label={getGenderFormatMessage('new')} angle="107deg" withSpout={false} />}</div>
        </div>
        <div className={styles.content}>
          <ContentHeader
            type={type}
            title={title}
            professions={professions}
            grades={grades}
            isInFavorites={isInFavorites}
            onOpenClick={onOpenClick}
            onShareClick={onShareClick}
            onFavoritesClick={onFavoritesClick}
            openLabel={myBookData ? 'preview_book_open_last_page' : ''}
            openButtonType="primary"
            marketingUrl={bookMarketingURL}
            hideButtons={hideButtons}
            documentId={documentId}
            itemDimensions={itemDimensions}
          />
          {isGuest && (
            <AuthorizationMessage
              message={getGenderFormatMessage('book_view_first')}
              openText={getGenderFormatMessage('book_peek')}
              onOpenClick={onOpenClick}
              isPurchaseBtn={false}
            />
          )}
          {yearCycles && <div className={styles.yearCycles}>{yearCycles}</div>}
          <StuckMenu
            type="item"
            stuckUpClassName={styles.stuckUpClassName}
            stuckMenuClassName={styles.stuckMenuClassName}
            layoutCenterClassName={styles.layoutCenterClassName}
            layoutTop={
              <>
                <div className={styles.tabs}>
                  <Tabs
                    tabs={[
                      { value: 'summary', text: getGenderFormatMessage('preview_mobile_book_summary'), totalCount: -1 },
                      { value: 'tableOfContent', text: getGenderFormatMessage('preview_mobile_book_tableOfContent'), totalCount: -1 },
                      { value: 'syllabus', text: getGenderFormatMessage('preview_mobile_book_syllabus'), totalCount: -1 }
                    ]}
                    addAllTab={false}
                    initToCenter={true}
                    activeTabKey={selectedTab}
                    onTabClick={({ value }) => {
                      setSelectedTab(value);
                    }}
                  />
                </div>
              </>
            }
          />

          <div>
            {selectedTab === 'summary' && (
              <>
                {/* summary */}
                <div className={styles.row}>{summary}</div>

                {/* searchReferences */}
                {searchReferences && (
                  <div className={styles.bordered}>
                    <h3 className={styles.label}>{getGenderFormatMessage('search_in_book')}</h3>
                    <ul className={`${styles.list} ${styles.tableOfContent} ${searchReferences.length < 5 ? styles.noScroll : styles.withScroll}`}>
                      {searchReferences.map(({ href, chapter, title }, index) => (
                        <li key={index}>
                          <Href to={href} target="_blank">
                            <span>
                              <strong>{chapter}</strong>
                              {title ? ' - ' : ''} {title}
                            </span>
                            <Icon icon="arrow" width=".5rem" height=".7rem" />
                          </Href>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/*  itemTypes */}
                <div className={styles.row}>
                  {itemTypes?.map((itemType, index) => (
                    <div key={index} className={styles.itemType}>
                      <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
                      <div>{itemType.title}</div>
                    </div>
                  ))}
                </div>

                {/* references */}
                <div className={`${styles.references} ${styles.margined}`}>
                  <ReferencesList
                    title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage('courses')}`}
                    list={references}
                    startLinesCount={references ? references.length : 3}
                  />
                </div>
              </>
            )}
            {selectedTab === 'tableOfContent' && (
              <>
                {/* table_of_contents */}
                <TableOfContent data={additionalInfo?.tableOfContent} contextURL={contextURL} alwaysOpen={true} />
              </>
            )}
            {selectedTab === 'syllabus' && (
              <>
                {/* syllabus */}
                <SubjectsList list={syllabus} title={getGenderFormatMessage('syllabus_subject')} startLinesCount={syllabus ? syllabus.length : 3} />

                {/* skills */}
                {skills?.length > 0 && (
                  <>
                    <div className={styles.bordered}>
                      <SubjectsList list={skills} title={getGenderFormatMessage('skills')} startLinesCount={skills ? skills.length : 3} />
                    </div>
                  </>
                )}
              </>
            )}
            {selectedTab === 'summary' && nanoCourseRecommendations}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.tags}>{isNew && <NewTag label={getGenderFormatMessage('new')} />}</div>
      <div className={styles.banner}>
        <img src={banner} alt={title} onError={onBannerError} onLoad={onBannerLoad} width={666} height={239} />
      </div>
      <div className={styles.content}>
        <ContentHeader
          type={type}
          title={title}
          professions={professions}
          grades={grades}
          summary={summary}
          isInFavorites={isInFavorites}
          onOpenClick={onOpenClick}
          onShareClick={onShareClick}
          onFavoritesClick={onFavoritesClick}
          documentId={documentId}
          openLabel={myBookData ? 'preview_book_open_last_page' : ''}
          openButtonType="primary"
          marketingUrl={bookMarketingURL}
          hideButtons={hideButtons}
          itemDimensions={itemDimensions}
        />

        {searchReferences && (
          <div className={styles.bordered}>
            <h3 className={styles.label}>{getGenderFormatMessage('search_in_book')}</h3>
            <ul className={`${styles.list} ${styles.tableOfContent} ${searchReferences.length < 5 ? styles.noScroll : styles.withScroll}`}>
              {searchReferences.map(({ href, chapter, title }, index) => (
                <li key={index}>
                  <Href to={href} target="_blank">
                    <span>
                      <strong>{chapter}</strong>
                      {title ? ' - ' : ''} {title}
                    </span>
                    <Icon icon="arrow" width=".5rem" height=".7rem" />
                  </Href>
                </li>
              ))}
            </ul>
          </div>
        )}
        {/*  itemTypes */}
        <div className={styles.row}>
          {itemTypes?.map((itemType, index) => (
            <div key={index} className={styles.itemType}>
              <Icon icon={itemType.icon} title={itemType.title} width={22} height={22} fill="transparent" stroke="currentColor" />
              <div>{itemType.title}</div>
            </div>
          ))}
        </div>
        {isGuest && (
          <AuthorizationMessage
            message={getGenderFormatMessage('book_view_first')}
            openText={getGenderFormatMessage('book_peek')}
            onOpenClick={onOpenClick}
            isPurchaseBtn={false}
          />
        )}
        <TableOfContent data={additionalInfo?.tableOfContent} contextURL={contextURL} title={getGenderFormatMessage('table_of_contents')} />
        {/* syllabus */}
        <SubjectsList list={syllabus} title={getGenderFormatMessage('syllabus_subject')} />
        {skills?.length > 0 && (
          <>
            <div className={styles.bordered}>
              <SubjectsList list={skills} title={getGenderFormatMessage('skills')} />
            </div>
          </>
        )}
        <div className={`${styles.references} ${styles.margined}`}>
          <ReferencesList title={`${getGenderFormatMessage('out_of')} ${getGenderFormatMessage('courses')}`} list={references} />
        </div>

        {/* Temporary removed nanoCourseRecommendations (remove the "false" keyword to enable again) */}
        {false && nanoCourseRecommendationsItems && nanoCourseRecommendationsItems.length > 0 && (
          <div className={`${styles.bordered} ${styles.overflowVisible}`}>{nanoCourseRecommendations}</div>
        )}
      </div>
    </div>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  banner: PropTypes.string.isRequired,
  professions: PropTypes.string.isRequired,
  grades: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  syllabus: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string,
      subSubject: PropTypes.string,
      details: PropTypes.string
    })
  ),
  tableOfContent: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      chapters: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired
        })
      )
    })
  ),
  searchReferences: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      chapter: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  references: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  ),
  isInFavorites: PropTypes.bool,
  isMobileSupported: PropTypes.bool,
  isNew: PropTypes.bool,
  onOpenClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onFavoritesClick: PropTypes.func.isRequired,
  onBannerError: PropTypes.func
};

export default Item;
