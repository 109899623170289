import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import Button from '../Button';
import { getInnerUrl } from '../../utils/urls';
import { CacheProvider } from '../../utils/cacheProvider';
import { safelyGetInnerHTML } from '../../utils/html';

import styles from './DominateSystemMessage.module.scss';

const DominateSystemMessage = ({ title = '', content = '', showButton = false, buttonText = '', link = '', linkTarget = '', show = false, withCloseButton = true, handleClose, children, style }) => {
  const history = useHistory();
  const [relativeLink, setRelativeLink] = useState(false);
  const [isShowDominateSystemMessage, setIsShowDominateSystemMessage] = useState(false);

  useEffect(() => {
    const isClosed = CacheProvider.get('dominateSystemMessageClosed') ?? false
    setIsShowDominateSystemMessage(!isClosed);
  }, []);

  useEffect(() => {
    setRelativeLink(getInnerUrl(link));
  }, [link]);

  const close = () => {
    if (handleClose) {
      handleClose();
      CacheProvider.set('dominateSystemMessageClosed', 'true');
    }
  };

  const onContentClick = (e) => {
    const aTag = e.target.closest("a");
    if (aTag && e.currentTarget.contains(aTag)) {
      if (!aTag.target || aTag.target !== '_blank') {
        e.preventDefault()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        let linkTag = window.document.createElement('a');
        linkTag.href = getInnerUrl(aTag.href);
        let historyState = {
          pathname: linkTag.pathname,
          search: linkTag.search
        };
        history.push(`${historyState.pathname}${historyState.search}`, historyState);
      }
      close();
    }
  };

  return (<>
    {isShowDominateSystemMessage && (
      <div className={styles.dominateSystemMessage}>
        <Modal isActive={show} closeButtonLabel={''} onClose={close} className={styles.modal} withCloseButton={withCloseButton}>
          <div className={styles.systemMessagePopup}>
            <div className={styles.systemMessagePopup_scroller}>
              {title && <div className={styles.systemMessagePopup_title}>{title}</div>}
              {content && <div
                className={styles.systemMessagePopup_content}
                onClick={onContentClick}
                style={style}
              >
                {safelyGetInnerHTML(content || '', true)}
              </div>}
              {!content && <div className={styles.systemMessagePopup_content}>{children}</div>}
            </div>
            {showButton && <div className={styles.systemMessagePopup_footer}>
              <Button onClick={close} {...(relativeLink && linkTarget ? { href: relativeLink, target: linkTarget } : {})} className={styles.systemMessagePopup_button}><span className={styles.systemMessagePopup_buttonText}>{buttonText}</span></Button>
            </div>}
          </div>
        </Modal>
      </div>)
    }
  </>
  )
};

DominateSystemMessage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  linkTarget: PropTypes.oneOf(['', '_top', '_blank']),
  show: PropTypes.bool,
  handleClose: PropTypes.func
};

export default DominateSystemMessage;